if ($('#feedbacksDatatable').length > 0) {
    url = $('#feedbacksDatatable').attr('data-url');
    dt = $("#feedbacksDatatable").DataTable({
        searchDelay: 500,
        order: [[0, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: {
            "url": url,
            "type": "GET",
            "data": function (d) {
                d['date_range_filter'] = $('#feedbacksDatatable_date_range_filter').val()
                d['typeSearch'] = $('#feedbacksDatatable_type_search').val()
            }
        },
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data: 'partner', name: 'partner.label'},
            {data: 'type', name: 'type'},
            {data: 'user', name: 'user.first_name'},
            {data: 'customer', name: 'customer.first_name'},
            {data: 'created_at', name: 'created_at'},
            {data: 'actions'},
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true,
                searchable: false
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: true
            },
            {
                targets: 4,
                orderable: true
            },
            {
                targets: 5,
                orderable: false,
                searchable: false
            },
        ]
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-feedbacksDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
    $('body').on('change', '#feedbacksDatatable_date_range_filter, #feedbacksDatatable_type_search', function () {
        dt.draw();
    })
}
