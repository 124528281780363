body = $('body');

body.on('change', '.SQE_zoneGroup_radio, .SQE_price_free', function () {
    let partnerId = $('#partner').val();
    let customerId = $('#customer').val();
    let agreementId = $('#agreement').val();
    let freeSessions = $('.SQE_price_free:checked').val();

    let selectedZoneGroupSQEEl = $('.SQE_zoneGroup_radio:checked');
    let zoneGroupSQEId = selectedZoneGroupSQEEl.val();

    let url = selectedZoneGroupSQEEl.attr('data-url');
    let method = selectedZoneGroupSQEEl.attr('data-method');

    $('.SQE_sizeType_radio').prop('checked', false);
    $('#SQE_sizeTypeContainer').addClass('d-none');
    $('#SQE_zonesContainer').removeClass('d-none');
    getZones(
        url,
        method,
        partnerId,
        customerId,
        zoneGroupSQEId,
        agreementId,
        null,
        freeSessions
    );
});
