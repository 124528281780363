if ($('#customerReferralsDatatable').length > 0) {
    url = $('#customerReferralsDatatable').attr('data-url');
    dt = $("#customerReferralsDatatable").DataTable({
        searchDelay: 500,
        order: [[1, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            { data : 'id' },
            { data : 'created_at' },
            { data : 'email' },
            { data : 'customer_1.fullName' },
            { data : 'customer_2.fullName' },
            { data : 'actions' },
        ],
        columnDefs: [
            {
                targets: 0,
                visible : false,
                searchable : false,
                orderable: false
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: true
            },
            {
                targets: 4,
                orderable: true
            },
            {
                targets: 5,
                orderable: false
            },
        ]
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-customerReferralsDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}
