if ($('#partnerReportsDatatable').length > 0) {
    url = $('#partnerReportsDatatable').attr('data-url');
    dt = $("#partnerReportsDatatable").DataTable({
        searchDelay: 500,
        order: [[0, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: {
            "url": url,
            "type": "GET",
            "data": function (d) {
                d['date_range_filter'] = $('#partnerReportsDailyDatatable_date_range_filter').val()
                d['date_range_filter_status'] = $('#partnerReportsDailyDatatable_date_range_filter_status').prop('checked');
            }
        },
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {
                data: 'user.first_name',
                name: 'user.first_name'
            },
            {
                data: 'date',
                searchable: false
            },
            {
                data: 'total'
            },
            {
                data: 'actions',
                orderable: false,
                searchable: false
            },
            {
                data: 'user.last_name',
                name: 'user.last_name',
                visible: false,
                searchable: true
            },
            {
                data: 'real_date',
                name: 'real_date',
                visible: false,
                searchable: true
            },
        ],
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-partnerReportsDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });

    $('body').on('change', '#partnerReportsDailyDatatable_date_range_filter, #partnerReportsDailyDatatable_date_range_filter_status', function () {
        dt.draw();
    })
}
