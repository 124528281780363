if($('#productHistoryDatatable').length > 0) {
    url = $('#productHistoryDatatable').attr('data-url');
    dt = $("#productHistoryDatatable").DataTable({
        searchDelay: 500,
        order: [[3, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data : 'user'},
            {data : 'quantity'},
            {data : 'reason'},
            {data : 'date'},
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: true
            },
        ]
    });

    dt.on('draw', function() {
        refreshFsLightbox();
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-productHistoryDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}
