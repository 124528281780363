if ($('#customer-agreements-datatable').length > 0) {
    let url = $('#customer-agreements-datatable').attr('data-url');
    console.log(url);
    let dt = $("#customer-agreements-datatable").DataTable({
        searchDelay: 500,
        order: [[3, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data: 'id'},
            {data: 'service'},
            {data: 'date'},
            {data: 'actions'}
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: false,
                searchable: false,
                visible: false
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: false,
                searchable: false
            }
        ]
    });

    let a = document.querySelector('[data-kt-user-table-filter-customer-agreements-datatable="search"]');
    a.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}

