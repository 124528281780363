import Swal from "sweetalert2";

window.getServiceForm = function getServiceForm(url, method, service) {
    $('#kt_accordion_2').addClass('overlay-block');
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            service: service,
        },
        success: function (data) {
            $(data.update).html(data.html);
            $('#kt_accordion_2').removeClass('overlay-block');
            $('#new_service_form_group_btn').click();
            $('#signature').jSignature();
            AnyPickerInit('Data de Consentimento');
            KTImageInput.createInstances();
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    })
}

$('body').on('change', '.new_service_radio', function () {
    var url = $(this).attr('data-url');
    var method = $(this).attr('data-method');
    var service = $(this).val();
    getServiceForm(url, method, service);
});

$('body').on('click', '#signature_delete', function () {
    $('#signature').jSignature('reset');
});

$('body').on('change', '#confirm_signature', function () {
    let totalTime = 5000;
    let html = window.language['services'][$(this).attr('data-service-type')]['signature_warning'];

    if ($(this).is(':checked')) {
        Swal.fire({
            "title": "ATENÇÃO",
            "html": html,
            "type": "warning",
            "confirmButtonClass": "btn btn-success",
            "cancelButtonClass": "btn btn-danger",
            "showCancelButton": false,
            "confirmButtonText": 'Continuar',
            "cancelButtonText": "Voltar",
            customClass: {
                container: 'text-white',
                popup: 'bg-danger w-100 h-100',
                header: '',
                title: 'text-white swal-title-custom',
                closeButton: 'your-close-button-class',
                icon: 'border-white text-white',
                content: 'text-white swal-content-custom',
                input: 'your-input-class',
                actions: 'your-actions-class',
                confirmButton: 'your-confirm-button-class',
                cancelButton: 'your-cancel-button-class',
                footer: 'your-footer-class'
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
                setTimeout(() => {
                    Swal.hideLoading()
                }, totalTime)
            },
            willClose: () => {
                Swal.hideLoading();
            }
        }).then(
            function (isConfirm) {
                if (isConfirm.value === true) {
                    Swal.close();
                }
            })
    }
});
