import Swal from 'sweetalert2';

$('body').on('change', '#next_session_date_customer_id', function () {
    let nextSessionAtEl = $('#next_session_at');
    if ($(this).val() === '') {
        return;
    }
    if ($(this).val() === 'all') {
        nextSessionAtEl.val('');
        nextSessionAtEl.trigger('change');
    }

    $.ajax({
        url: $('#next_session_date_customer_id_wrapper').attr('data-action'),
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {customer_id: $(this).val()},
        success: function (data) {
            nextSessionAtEl.val(data.data.next_session_at);
            nextSessionAtEl.trigger('change');

            nextSessionAtEl.AnyPicker('destroy');

            AnyPickerInit();
        },
        error: function (data) {
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao buscar a próxima sessão do cliente'
            });
        }
    });
})
