function format(item, state) {
    if (!item.id) {
        return item.text;
    }
    var countryUrl = "https://www.mundohbl.com/images/flags/";
    var stateUrl = "https://oxguy3.github.io/flags/svg/us/";
    var url = state ? stateUrl : countryUrl;
    var img = $("<img>", {
        class: "img-flag",
        width: 26,
        src: url + item.element.value.toLowerCase() + ".svg"
    });
    var span = $("<span>", {
        text: " " + item.text
    });
    span.prepend(img);
    return span;
}

function formatPhone(item, state) {
    if (!item.id) {
        return item.text;
    }
    var countryUrl = "https://www.mundohbl.com/images/flags/";
    var stateUrl = "https://oxguy3.github.io/flags/svg/us/";
    var url = state ? stateUrl : countryUrl;
    var img = $("<img>", {
        class: "img-flag",
        width: 26,
        src: url + item.element.getAttribute('data-country').toLowerCase() + ".svg"
    });
    var span = $("<span>", {
        text: " " + item.text
    });
    span.prepend(img);
    return span;
}

$('select.select-countries').each(function () {
    let dropdownParent = $(this).data('parent');
    if (dropdownParent && dropdownParent.length === 0) {
        dropdownParent = 'body';
    }

    $(this).select2({
        dropdownParent: dropdownParent,
        templateResult: function (item) {
            return format(item, false);
        }
    });
});

$('select.select-phones').each(function () {
    let dropdownParent = $(this).data('parent');
    if (dropdownParent && dropdownParent.length === 0) {
        dropdownParent = 'body';
    }

    $(this).select2({
        dropdownParent: dropdownParent,
        templateResult: function (item) {
            return formatPhone(item, false);
        }
    });
});


window.initSelect2Ajax = function initSelect2Ajax() {
    $('select.select2-ajax').each(function () {
        let dropdownParent = $(this).data('parent');
        if (dropdownParent.length === 0) {
            dropdownParent = 'body';
        }
        $(this).select2({
            dropdownParent: dropdownParent,
            ajax: {
                url: $(this).data('url'),
                type: "GET",
                cache: true
            },
            placeholder: 'Escolha um cliente',
            results: function (data) {
                return {results: data};
            }
        });
    });
}

window.initSelect2 = function initSelect2() {
    $('select.select2-init').each(function () {
        let dropdownParent = $(this).data('parent');
        if (dropdownParent.length === 0) {
            dropdownParent = 'body';
        }
        $(this).select2({
            dropdownParent: dropdownParent,
            results: function (data) {
                return {results: data};
            }
        });
    });
}

initSelect2Ajax();
initSelect2();
