import printJS from "print-js";

body.on('click', '.agreement_show', function () {
    $.ajax({
        url: $(this).attr('data-action'),
        type: $(this).attr('data-method'),
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {},
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#agreement-container').html(data.html3).removeClass('d-none');
                $('#session-container').html('');
                $('html, body').animate({
                    scrollTop: $('#agreement-container').offset().top
                });
                initSignatures();
                AnyPickerInit('Data de Consentimento');
                KTImageInput.createInstances();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        }
    })
});

body.on('click', '#print-current-agreement', function () {
    let cssArr = [];
    $('link[rel="stylesheet"]').each(function () {
        cssArr.push($(this).attr('href'));
    });
    console.log(cssArr);
    printJS({
        printable: 'agreement-body',
        type: 'html',
        css: cssArr
    })
});
