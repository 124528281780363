import moment from "moment";

body.on('click', '.user-timetrack-start-btn', function () {
    initTimetrack($(this).attr('data-url'));
});

body.on('click', '.user-timetrack-stop-btn', function () {
    stopTimetrack($(this).attr('data-url'));
});

function initTimetrack(url) {
    $.ajax({
        url: url,
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        success: function (data) {
            addHtmlToPopupWrapper(data.modal_html, data.popup_html)
                .then(function () {
                    KTMenu.init();
                    KTMenu.init();
                    prepareTimetrackModal();
                });
        },
        error: function (err) {
        },
        complete: function () {
            $('#user-timetrack-modal').modal('hide');
        }
    });
}

function stopTimetrack(url) {
    $.ajax({
        url: url,
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data: {
            '_method': 'PATCH'
        },
        dataType: 'json',
        success: function (data) {
            addHtmlToPopupWrapper(data.modal_html, data.popup_html)
                .then(function () {
                    KTMenu.init();
                    KTMenu.init();
                });
        },
        error: function (err) {
        },
        complete: function () {
            $('#user-timetrack-modal').modal('hide');
        }
    });
}

window.showTimetrackModal = function showTimetrackModal() {
    $('#user-timetrack-modal').modal('show');
}

function prepareTimetrackModal() {
    let modal = $('#user-timetrack-modal')
    let hours = modal.data('hours');
    let minutes = modal.data('minutes');

    // Calculate the time until the next 6:15 PM
    let now = moment();
    let targetTime = moment().set({hour: hours, minute: minutes, second: 0, millisecond: 0}); // Set the target time to today at 6:15 PM
    if (now.isAfter(targetTime)) {
        targetTime.add(1, 'day'); // If the target time is in the past, set it to tomorrow
    }
    let timeUntilTarget = targetTime.diff(now);

    // Set a timeout to execute executeAt615PM() when the time reaches 6:15 PM
    window.showTimetrackModalTimer = setTimeout(showTimetrackModal, timeUntilTarget);
}

function addHtmlToPopupWrapper(modalHtml, popupHtml) {
    return new Promise((resolve) => {
        $('#user-timetrack-modal').modal('hide');
        $('#timetrack-modal-wrapper').html(modalHtml);
        $('#user-timetrack-popup-wrapper').html(popupHtml);
        resolve();
    });
}

if ($('.user-timetrack-stop-btn').length > 0) {
    prepareTimetrackModal();
}
