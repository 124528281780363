function startchartProductByUser() {
    var element = document.getElementById('chartProductByUser');
    let loadingEl = $('#chartProductByUserLoading');
    var jqEl = $('#chartProductByUser');
    var url = jqEl.attr('data-url');
    var method = jqEl.attr('data-action');
    var user_id = $('#chartProductByUser_user_id').val();
    var dates = $('#chartProductByUser_dates').val();

    if (!element) {
        return;
    }

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            'user_id' : user_id,
            'dates' : dates
        },
        beforeSend: function() {
            loadingEl.removeClass('d-none');
        },
        success: function (data) {
            var height = parseInt(KTUtil.css(element, 'height'));
            var labelColor = KTUtil.getCssVariableValue('#A1A5B7');
            var borderColor = KTUtil.getCssVariableValue('#eff2f5');
            var baseColor = KTUtil.getCssVariableValue('#009ef7');
            var secondaryColor = KTUtil.getCssVariableValue('#E4E6EF');

            var options = {
                series: data.series,
                chart: {
                    fontFamily: 'inherit',
                    type: 'donut',
                    height: height,
                    toolbar: {
                        show: true
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    showAlways: true,
                                    show: true
                                }
                            }
                        }
                    }
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: true
                },
                labels: data.categories,
                fill: {
                    opacity: 1
                },
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0
                        }
                    },
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                            value: 0
                        }
                    }
                },
                tooltip: {
                    style: {
                        fontSize: '12px'
                    }
                },
                grid: {
                    borderColor: borderColor,
                    strokeDashArray: 4,
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                }
            };
            jqEl.attr('data-loaded', 1);
            window.chartProductByUser = new ApexCharts(element, options);
            loadingEl.addClass('d-none');
            chartProductByUser.render();
        }
    });
}


$('body').on('change', '.chartProductByUserInput', function() {
    let loadingEl = $('#chartProductByUserLoading');
    let jqEl = $('#chartProductByUser');
    let url = jqEl.attr('data-url');
    let method = jqEl.attr('data-action');
    let user_id = $('#chartProductByUser_user_id').val();
    let dates = $('#chartProductByUser_dates').val();

    if(jqEl.attr('data-loaded') == 0) {
        return startchartProductByUser();
    }

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            'user_id' : user_id,
            'dates' : dates
        },
        beforeSend: function() {
            loadingEl.removeClass('d-none');
            jqEl.addClass('d-none');
        },
        success: function (data) {
            loadingEl.addClass('d-none');
            jqEl.removeClass('d-none');
            chartProductByUser.updateSeries(data.series);
        }
    });
});

if($('#chartProductByUser').length > 0) {
    let start = moment().subtract(29, "days");
    let end = moment();

    function cb(start, end) {
        $("#chartProductByUser_dates").html(start.format("D/MMMM/YYYY") + " - " + end.format("D/MMMM/YYYY"));
    }

    $("#chartProductByUser_dates").daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
            "Ontem": [moment().subtract(1, "days"), moment().subtract(1, "days")],
            "Ultimos 7 Dias": [moment().subtract(6, "days"), moment()],
            "Ultimos 30 Dias": [moment().subtract(29, "days"), moment()],
            "Este Mês": [moment().startOf("month"), moment().endOf("month")],
            "Ultimo Mês": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
            "Este Ano": [moment().startOf("year"), moment().endOf("year")],
            "Ano Anterior": [moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year")],
        }
    }, cb);

    cb(start, end);
}
