url = $('#customersDatatable').attr('data-url');
dt = $("#customersDatatable").DataTable({
    searchDelay: 2000,
    order: [[7, 'desc']],
    language: window.datatableLanguage,
    searching: true,
    serverSide: true,
    processing: true,
    responsive: true,
    ajax: url,
    pageLength: 25,
    select: {
        style: 'os',
        selector: 'td:first-child',
        className: 'row-selected'
    },
    columns: [
        {data: 'first_name'},
        {data: 'gender'},
        {data: 'dob'},
        {data: 'city'},
        {data: 'phone'},
        {data: 'email'},
        {data: 'actions'},
        {data: 'created_at'},
        {data: 'last_name'}
    ],
    columnDefs: [
        {
            targets: 0,
            orderable: true
        },
        {
            targets: 1,
            orderable: true
        },
        {
            targets: 2,
            orderable: true
        },
        {
            targets: 3,
            orderable: true
        },
        {
            targets: 4,
            orderable: true
        },
        {
            targets: 5,
            orderable: true
        },
        {
            targets: 6,
            orderable: false,
            searchable: false
        },
        {
            targets: 7,
            orderable: false,
            searchable: false,
            visible: false
        },
        {
            targets: 8,
            orderable: false,
            searchable: true,
            visible: false
        }
    ]
});

const filterSearch = document.querySelector('[data-kt-customers-table-filter="search"]');
filterSearch.addEventListener('keyup', function (e) {
    dt.search(e.target.value).draw();
});

