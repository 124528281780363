if ($('#servicesDatatable').length > 0) {
    url = $('#servicesDatatable').attr('data-url');
    dt = $("#servicesDatatable").DataTable({
        searchDelay: 500,
        order: [[4, 'asc']],
        language: window.datatableLanguage,
        searching: true,
        processing: true,
        responsive: true,
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-servicesDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}
