if($('#userVehiclesSpendingsDatatable').length > 0) {
    url = $('#userVehiclesSpendingsDatatable').attr('data-url');
    dt = $("#userVehiclesSpendingsDatatable").DataTable({
        searchDelay: 500,
        order: [[1, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data: 'plate'},
            {data: 'date'},
            {data: 'liters'},
            {data: 'total'},
            {data: 'kilometers'},
            {data: 'location'},
            {data: 'card'},
            {data: 'actions'}
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: true
            },
            {
                targets: 4,
                orderable: true
            },
            {
                targets: 5,
                orderable: true
            },
            {
                targets: 6,
                orderable: true
            },
            {
                targets: 7,
                orderable: false
            }
        ]
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-userVehiclesSpendingsDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}
