if ($('#userStockHistoryDatatable').length > 0) {
    url = $('#userStockHistoryDatatable').attr('data-url');
    dt = $("#userStockHistoryDatatable").DataTable({
        searchDelay: 500,
        order: [[0, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data: 'created_at'},
            {data: 'product'},
            {data: 'quantity'},
            {data: 'reason'}
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: true
            }
        ]
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-userStockHistoryDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}
