function addSaleProductModal(product_id, url, method) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            product_id: product_id
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#add_sale_product_id').val(data.id);
                $('#add_sale_product_warning').addClass('d-none');
                $('#add_sale_product_quantity').attr('disabled', true).attr('max', data.stock).val(0);
                $('#add_sale_product_price, #add_sale_product_total').val(data.price);
                $('#add_sale_product_submit').addClass('d-none');
                $('.product_promo_input_wrappers').addClass('d-none');
                if (data.hasSubproducts === false) {
                    $('.product_promo_input_wrappers').removeClass('d-none');
                }
                if (data.stock > 0) {
                    $('#add_sale_product_quantity').val(1).attr('disabled', false);
                    $('#add_sale_product_submit').removeClass('d-none');
                } else {
                    $('#add_sale_product_warning').removeClass('d-none');
                }
                $('#add_sale_product_modal').modal('show');
            } else {

            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        }
    });
}

body.on('change', '#add_sale_product_promo_price', function () {
    if ($(this).is(':checked')) {
        $('#add_sale_product_price').removeAttr('disabled').focus();
        $('#add_sale_product_promo_free').prop('checked', false);
    } else {
        $('#add_sale_product_price').attr('disabled', 'disabled');
    }
});

body.on('change', '#add_sale_product_promo_free', function () {
    if ($(this).is(':checked')) {
        $('#add_sale_product_promo_price').prop('checked', false);
        $('#add_sale_product_price').prop('disabled', true).val('0.00€');
    } else {
        $('#add_sale_product_price').prop('disabled', false);
    }
})

body.on('keyup', '#add_sale_product_price', function () {
    $(this).val(number_format($(this).val(), 2, '.', '') + '€');
    let quantity = parseInt($('#add_sale_product_quantity').val());
    let price = $(this).val();
    price = getFloatFromMoney(price, '€');
    let total = price * quantity;
    $('#add_sale_product_total').val(number_format(total, 2, '.', '') + '€');
});

body.on('change', 'input#add_sale_product_quantity', function () {
    let quantity = parseInt($(this).val());
    let price = $('#add_sale_product_price').val();
    price = getFloatFromMoney(price, '€');
    let total = price * quantity;
    $('#add_sale_product_total').val(number_format(total, 2, '.', '') + '€');
});

$('body').on('click', '.sale_product_add', function () {
    addSaleProductModal($(this).attr('data-id'), $(this).attr('data-action'), $(this).attr('data-method'));
});

function addSaleProduct(product_id, price, promo_price, promo_free, quantity, url, method) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            product_id: product_id,
            quantity: quantity,
            price: price,
            promo_price: promo_price,
            promo_free: promo_free
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#product_' + product_id).remove();
                $('#customerNewSaleTable tbody').append(data.html);
                $('.modal').modal('hide');
            } else {

            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        }
    });
}

$('body').on('click', '#add_sale_product_submit', function () {
    let quantity = $('#add_sale_product_quantity').val();
    let product = $('#add_sale_product_id').val();
    let price = getFloatFromMoney($('#add_sale_product_price').val(), '€');
    let promo_price = $('#add_sale_product_promo_price').is(':checked');
    let promo_free = $('#add_sale_product_promo_free').is(':checked');
    addSaleProduct(product, price, promo_price, promo_free, quantity, $('#add_sale_product_id').attr('data-action'), $('#add_sale_product_id').attr('data-method'));
});

function deleteSaleProduct(id, url, method) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            product_id: id
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#sale_product_' + id).remove();
                $('#customerProductSaleTable tbody').append(data.html);
                $('.modal').modal('hide');
            } else {

            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        }
    });
}

$('body').on('click', '.sale_product_delete', function () {
    deleteSaleProduct($(this).attr('data-id'), $(this).attr('data-action'), $(this).attr('data-method'));
});


body.on('change', 'input.sale_product_quantity', function () {
    quantity = parseInt($(this).val());
    price = $('#sale_product_price_' + $(this).attr('data-id') + ' input').val();
    price = getFloatFromMoney(price, '€');
    total = price * quantity;
    $('#sale_product_total_' + $(this).attr('data-id')).html(number_format(total, 2, '.', '') + '€');
});


body.on('click', '.new_sale_view', function () {
    $.ajax({
        url: $(this).attr('data-action'),
        type: $(this).attr('data-method'),
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {},
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#products-container').html(data.html);
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        }
    });
});

body.on('click', '.sale_view_btn', function () {
    $.ajax({
        url: $(this).attr('data-action'),
        type: $(this).attr('data-method'),
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {},
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#products-container').html(data.html);
            } else {

            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        }
    });
});

body.on('click', '.sale_delete_btn', function () {
    $('#' + $(this).attr('data-id')).submit();
});
