if ($('#partnerFeedbacksDatatable').length > 0) {
    url = $('#partnerFeedbacksDatatable').attr('data-url');
    dt = $("#partnerFeedbacksDatatable").DataTable({
        searchDelay: 500,
        order: [[2, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data: 'type', name: 'type'},
            {data: 'customer', name: 'customer.first_name'},
            {data: 'created_at', name: 'created_at'},
            {data: 'actions'}
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: false
            },
        ]
    });

    dt.on('draw', function () {
        refreshFsLightbox();
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-partnerFeedbacksDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}
