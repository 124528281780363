function startchartService() {
    var element = document.getElementById('chartService');
    let loadingEl = $('#chartServiceLoading');
    var jqEl = $('#chartService');
    var url = jqEl.attr('data-url');
    var method = jqEl.attr('data-action');
    var partner_id = $('#chartService_partner_id').val();
    var dates = $('#chartService_dates').val();

    if (!element) {
        return;
    }

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            'partner_id': partner_id,
            'dates': dates
        },
        beforeSend: function () {
            loadingEl.removeClass('d-none');
        },
        success: function (data) {
            var height = parseInt(KTUtil.css(element, 'height'));
            var labelColor = KTUtil.getCssVariableValue('#a1a5b7');
            var borderColor = KTUtil.getCssVariableValue('#eff2f5');
            var baseColor = KTUtil.getCssVariableValue('#009ef7');
            var secondaryColor = KTUtil.getCssVariableValue('#e4e6ef');

            var options = {
                series: data.series,
                chart: {
                    fontFamily: 'inherit',
                    type: 'donut',
                    height: height,
                    toolbar: {
                        show: true
                    }
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                total: {
                                    showAlways: true,
                                    show: true,
                                    formatter: function (w) {
                                        return number_format(w.globals.seriesTotals.reduce((a, b) => a + b, 0), 2, '.', '') + '€';
                                    }
                                }
                            }
                        }
                    }
                },
                legend: {
                    show: true
                },
                dataLabels: {
                    enabled: true
                },
                labels: data.categories,
                fill: {
                    opacity: 1
                },
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0
                        }
                    },
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                            value: 0
                        }
                    }
                },
                tooltip: {
                    style: {
                        fontSize: '12px'
                    },
                    y: {
                        formatter: function (val) {
                            return number_format(val, 2, '.', '') + '€'
                        }
                    }
                },
                grid: {
                    borderColor: borderColor,
                    strokeDashArray: 4,
                    yaxis: {
                        lines: {
                            show: true
                        }
                    }
                }
            };
            jqEl.attr('data-loaded', 1);
            window.chartService = new ApexCharts(element, options);
            loadingEl.addClass('d-none');
            chartService.render();
        }
    });
}


$('body').on('change', '.chartServiceInput', function () {
    let loadingEl = $('#chartServiceLoading');
    let jqEl = $('#chartService');
    let url = jqEl.attr('data-url');
    let method = jqEl.attr('data-action');
    let partner_id = $('#chartService_partner_id').val();
    let dates = $('#chartService_dates').val();

    if (jqEl.attr('data-loaded') == 0) {
        return startchartService();
    }

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            'partner_id': partner_id,
            'dates': dates
        },
        beforeSend: function () {
            loadingEl.removeClass('d-none');
            jqEl.addClass('d-none');
        },
        success: function (data) {
            loadingEl.addClass('d-none');
            jqEl.removeClass('d-none');
            chartService.updateSeries(data.series);
        }
    });
});


if ($('#chartService').length > 0) {
    let start = moment().subtract(29, "days");
    let end = moment();

    function cb(start, end) {
        $("#chartService_dates").html(start.format("D/MMMM/YYYY") + " - " + end.format("D/MMMM/YYYY"));
    }

    $("#chartService_dates").daterangepicker({
        startDate: start,
        endDate: end,
        ranges: {
            "Ontem": [moment().subtract(1, "days"), moment().subtract(1, "days")],
            "Ultimos 7 Dias": [moment().subtract(6, "days"), moment()],
            "Ultimos 30 Dias": [moment().subtract(29, "days"), moment()],
            "Este Mês": [moment().startOf("month"), moment().endOf("month")],
            "Ultimo Mês": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")],
            "Este Ano": [moment().startOf("year"), moment().endOf("year")],
            "Ano Anterior": [moment().subtract(1, "year").startOf("year"), moment().subtract(1, "year").endOf("year")],
        }
    }, cb);

    cb(start, end);
}
