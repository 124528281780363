require('./bootstrap');
require('jquery-migrate')
require('jquery-ui')

/* NODE MODULES */

//require('@ckeditor/ckeditor5-build-balloon')
//require('@ckeditor/ckeditor5-build-balloon-block')
//require('@ckeditor/ckeditor5-build-classic')
//require('@ckeditor/ckeditor5-build-inline')
//require('@ckeditor/ckeditor5-build-decoupled-document')
require('@fortawesome/fontawesome-free')
require('@shopify/draggable')
window.Tagify = require('@yaireo/tagify')
require('acorn')
window.ApexCharts = require('apexcharts')
require('autosize')
require('bootstrap-daterangepicker')
require('bootstrap-maxlength')
require('bootstrap-multiselectsplitter')
require('chalk')
require('chart.js')
require('clipboard')
require('countup.js')
require('cropperjs')
simplify = require('simplify-js')
require('datatables.net')
require('datatables.net-bs5')
require('datatables.net-buttons')
require('datatables.net-buttons-bs5')
require('datatables.net-colreorder')
require('datatables.net-colreorder-bs5')
require('datatables.net-datetime')
require('datatables.net-fixedcolumns')
require('datatables.net-fixedcolumns-bs5')
require('datatables.net-fixedheader')
require('datatables.net-fixedheader-bs5')
require('datatables.net-responsive')
require('datatables.net-responsive-bs5')
require('datatables.net-rowgroup')
require('datatables.net-rowgroup-bs5')
require('datatables.net-rowreorder')
require('datatables.net-rowreorder-bs5')
require('datatables.net-scroller')
require('datatables.net-scroller-bs5')
require('datatables.net-select')
require('datatables.net-select-bs5')
require('dropzone')
require('es6-promise')
require('es6-promise-polyfill')
require('es6-shim')
require('leaflet')
require('esri-leaflet')
require('esri-leaflet-geocoder')
require('flatpickr')
require('flot')
require('fslightbox')
require('fullcalendar')
require('inputmask')
require('jkanban')
require('jquery.repeater')
require('jstree')
require('jszip')
window.moment = require('moment')
require('nouislider')
require('pdfmake')
require('prismjs')
require('quill')
require('select2')
window.html2canvas = require('html2canvas')
window.SmoothScroll = require('smooth-scroll')
require('sweetalert2')
require('tiny-slider')
require('tinymce')
require('toastr')
require('typed.js')
require('vis-timeline')
require('wnumb')
require('bootstrap-notify')

/* VENDOR */
require('./vendor');
window.FormValidation = require('./vendor/vendors/plugins/formvalidation/dist/amd/index')
require('./vendor/vendors/plugins/formvalidation/dist/amd/core/Plugin')
require('./vendor/custom/widgets')
require('./vendor/vendors/jSignature.min');
require('./vendor/custom/jquery.mask.min');
require('./vendor/custom/jquery.maskMoney.min');
window.AnyPicker = require('anypicker');
require('./custom/anypicker-i18n-pt')

/* Custom */
require('./custom/Datatables/configs/datatableLanguage')
require('./custom/RIFTHelper')
require('./custom/select2')
require('./custom/Datatables')
require('./custom/Filters')
require('./custom/Forms/ajaxForm')
require('./custom/Forms/selectService');
require('./custom/Forms/selectCustomerService');
require('./custom/Sessions/common');
require('./custom/CanvasClicker/imageClicker')
require('./custom/referralSystem/referralSystem')

/* Vehicles */
require('./custom/Vehicles/vehicleUserAddSelect')

/* Users */
require('./custom/Users/userVehicleAddSelect')
require('./custom/Users/userStockAddSelect')

/* Signatures */
require('./custom/signatures')
require('./custom/buttons')

/* Dashboard Partners */
require('./dashboardPartnersSearch')

/* Customer Sales */
require('./custom/Customer/sales')

/* Customer Agreements */
require('./custom/Customer/newService')
require('./custom/Customer/agreements')

/* Quality Control */
require('./custom/feedbacks/feedbacks')

/* Charts */
require('./custom/Analytics/serviceByUser')
require('./custom/Analytics/serviceByPartner')
require('./custom/Analytics/services')
require('./custom/Analytics/productsByPartner')
require('./custom/Analytics/productsByUser')
require('./custom/Analytics/products')

/* WhatApp Mass Component */
require('./custom/WhatsApp/whatsAppMassComponent')

/* Next Session Date */
require('./custom/NextSessionDate/nextSessionDate')
/* Cached Next Session Date */
require('./custom/CachedNextSessionDate/cached_next_session_date')

/* Timetrack */
require('./custom/Timetrack/timetrack')
