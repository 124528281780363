/*$.fn.dataTable.ext.search.push(
    function (settings, data, dataIndex) {
        let searchValue = dt.search();
        let lastName = data[4];
        if (typeof lastName === 'undefined') {
            return false;
        }

        return lastName.includes(searchValue);
    }
);
*/

if ($('#customersDatatable').length > 0) {
    require('./Datatables/partners.view__customers')
}

require('./Datatables/partners.view__customer__agreements')
require('./Datatables/partnerFeedbacks')
require('./Datatables/vehicles')
require('./Datatables/vehicleSpending')
require('./Datatables/vehicleUsers')
require('./Datatables/users');
require('./Datatables/userPartners')
require('./Datatables/userPartnersAdd')
require('./Datatables/userVehicles')
require('./Datatables/userVehiclesSpending')
require('./Datatables/userStock')
require('./Datatables/userStockHistory')
require('./Datatables/userSessions')
require('./Datatables/userShoots')
require('./Datatables/groups')
require('./Datatables/groupUsers')
require('./Datatables/shoots')
require('./Datatables/products')
require('./Datatables/productHistory')
require('./Datatables/productStock')
require('./Datatables/reportsDaily')
require('./Datatables/partnerReports')
require('./Datatables/customerProducts')
require('./Datatables/customerReferrals')
require('./Datatables/shootMachines')
require('./Datatables/userShootMachines')
require('./Datatables/globalCustomers')
require('./Datatables/feedbacks')
require('./Datatables/services')

require('./Datatables/userTimetracks')

require('./Datatables/customerAgreementSessions/DL')
require('./Datatables/customerAgreementSessions/DLF')
require('./Datatables/customerAgreementSessions/FR')
require('./Datatables/customerAgreementSessions/LP')
require('./Datatables/customerAgreementSessions/LV')
require('./Datatables/customerAgreementSessions/SQE')
require('./Datatables/customerAgreementSessions/SQJ')
require('./Datatables/customerAgreementSessions/VLC')
require('./Datatables/customerAgreementSessions/VSF')
require('./Datatables/customerAgreementSessions/FRVLF')
require('./Datatables/customerAgreementSessions/BTX')
require('./Datatables/customerAgreementSessions/DLVLC')
require('./Datatables/customerAgreementSessions/DLN')
require('./Datatables/customerAgreementSessions/AI')
require('./Datatables/customerAgreementSessions/TAT')
require('./Datatables/customerAgreementSessions/DL2025')
require('./Datatables/customerAgreementSessions/PC')
require('./Datatables/customerAgreementSessions/RMP')
