import html2canvas from "html2canvas";

window.imageClicker = function imageClicker(id) {
    window.imageClickerArr = window.imageClickerArr || [];
    window.imageClickerArr[id] = window.imageClickerArr[id] || {};
    window.imageClickerArr[id].points = window.imageClickerArr[id].points || [];


    const $radius = 20;

    //var element = $("#image_preview"); // global variable
    //var getCanvas; // global variable

    $('#' + id).on('click', function (ev) {
        var el = $('#'+id);
        var width = el.attr('data-width');
        var height = el.attr('data-height');
        const $this = $(this),
            r = parseInt($radius, 10), // Parse as Integer radix 10
            o = $this.offset(),
            y = ev.pageY - o.top,
            x = ev.pageX - o.left;
            $("<div />", {
                "class": "circle-imageClicker",
                css: {
                    top: y,
                    left: x,
                    //width: r * 2,
                    //height: r * 2,
                    width: width,
                    height: height
                },
                appendTo: $this // append to #image_preview!
            });

            // Append data to App.points
        window.imageClickerArr[id].points.push({x, y, r});
        // Test
        console.log(window.imageClickerArr[id].points)

        $('#' + id + '_input').val(JSON.stringify(window.imageClickerArr[id].points));
        $('#' + id + '_reset').removeClass('d-none');
    })
}

window.resetImageClicker = function resetImageClicker(id) {
    $('#' + id + ' .circle-imageClicker').remove();
    window.imageClickerArr[id].points = [];
    $('#' + id + '_input').val(JSON.stringify(window.imageClickerArr[id].points));
    return false;
}

$('body').on('click', '.imageClicker_reset', function () {
    $(this).addClass('d-none');
    return resetImageClicker($(this).attr('data-id'));
});
