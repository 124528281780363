window.KTUtil = require('./vendor/components/util')
require('./vendor/components/blockui')
require('./vendor/components/cookie')
require('./vendor/components/dialer')
require('./vendor/components/drawer')
require('./vendor/components/event-handler')
require('./vendor/components/feedback')
require('./vendor/components/image-input')
require('./vendor/components/menu')
require('./vendor/components/password-meter')
require('./vendor/components/scroll')
require('./vendor/components/search')
require('./vendor/components/stepper')
require('./vendor/components/sticky')
require('./vendor/components/swapper')
require('./vendor/components/toggle')

/* THEME */
require('./vendor/layout/aside')
require('./vendor/layout/explore')
require('./vendor/layout/search')
require('./vendor/layout/toolbar')
require('./vendor/layout/app')

/* INIT */
require('./vendor/vendors/plugins/apexchart.init')
//require('./vendor/vendors/plugins/bootstrap-markdown.init')
require('./vendor/vendors/plugins/datatables.init')
require('./vendor/vendors/plugins/dropzone.init')
//require('./vendor/vendors/plugins/prism.init')
require('./vendor/vendors/plugins/flatpickr.init')
require('./vendor/vendors/plugins/select2.init')
require('./vendor/vendors/plugins/sweetalert2.init')
