window.getSignatureData = function getSignatureData(id, type = 'base30') {
    let arrayData = $('#' + id).jSignature('getData', type);
    return 'data:' + arrayData.join(',');
}

window.setSignatureData = function setSignatureData(id, data) {
    $('#' + id).jSignature('setData', data);
}

window.setSignatureImage = function setSignatureImage(id) {
    if ($('#' + id).attr('data-import')) {
        let res = getSignatureData(id, 'svgbase64');
        let html = "<img class='w-100 mt-3 mb-3' id='" + id + "_image' src='" + res + "'>"
        $('#' + id).after(html);
    }
    $('#' + id).addClass('d-none');
}

window.initSignatures = function initSignatures() {
    $('.signature').each(function () {
        $(this).jSignature();
        if ($(this).attr('data-import')) {
            setSignatureData($(this).attr('id'), $(this).attr('data-import'));
        }
        if ($(this).attr('data-image') === 'true') {
            setSignatureImage($(this).attr('id'))
        }
    });
}

initSignatures()

$('body').on('click', '.signature_reset', function () {
    $('#' + $(this).attr('data-signature')).jSignature('reset');
});
