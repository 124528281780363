body = $('body');


body.on('click', '.DL_zoneGroup_radio', function () {
    id = $(this).val();

    var partner = $('#partner').val();
    var customer = $('#customer').val();
    var agreement = $('#agreement').val();

    if($('#DL_zoneGroup_' + id).hasClass('openSizeType')) {
        $('#DL_sizeTypeContainer').removeClass('d-none');
        $('#DL_zonesContainer').addClass('d-none');
    } else {
        $('.DL_sizeType_radio').prop('checked', false);
        $('#DL_sizeTypeContainer').addClass('d-none');
        $('#DL_zonesContainer').removeClass('d-none');
        getZones($(this).attr('data-url'), $(this).attr('data-method'), partner, customer, $(this).val(), agreement);
    }
});

body.on('click', '.DL_sizeType_radio', function () {
    var partner = $('#partner').val();
    var customer = $('#customer').val();
    var agreement = $('#agreement').val();

    if($(this).val() !== '' && $(this).val() !== null) {
        $('#DL_zonesContainer').removeClass('d-none');
        getZones($(this).attr('data-url'), $(this).attr('data-method'), partner, customer, $(this).attr('data-zone-group'), agreement, $(this).val());
    }
});
