window.dashboardPartners = [];
window.dashboardPartners['page'] = 1;
window.dashboardPartners['search'] = $('#dashboardPartnerSearch');
window.dashboardPartners['container'] = $('#partnersContainer');
window.dashboardPartners['loading'] = $('#partnersDashboardLoading');

if(window.dashboardPartners['container'].length > 0) {
    $(window).scroll(function () {
        if ($(window).scrollTop() + $(window).height() >= ($(document).height() - 100) && parseInt(window.dashboardPartners['container'].attr('data-infinite')) === 1) {
            window.dashboardPartners['page']++;
            loadPartnerCards(window.dashboardPartners['page']);
        }
    });
}

function loadPartnerCards() {
    $.ajax({
        url: window.dashboardPartners['search'].attr('data-url'),
        type: "POST",
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        data : {
            page : window.dashboardPartners['page'],
            search : window.dashboardPartners['search'].val()
        },
        dataType: 'json',
        beforeSend: function () {
            window.dashboardPartners['loading'].removeClass('d-none').addClass('d-flex');
            window.dashboardPartners['container'].attr('data-infinite', 0);
        },
        success: function(data) {
            window.dashboardPartners['loading'].removeClass('d-flex').addClass('d-none');
            if (data.html.length === 0) {
                return;
            }
            window.dashboardPartners['container'].append(data.html);
            window.dashboardPartners['container'].attr('data-infinite', 1);
        }
    });
}

$('body').on('keyup', '#dashboardPartnerSearch', delay(function() {
    window.dashboardPartners['container'].html('');
    window.dashboardPartners['page'] = 1;
    loadPartnerCards()
}, 1000));

function delay(callback, ms) {
    var timer = 0;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}
