body = $('body');


body.on('click', '.DLN_zoneGroup_radio', function () {
    id = $(this).val();

    var partner = $('#partner').val();
    var customer = $('#customer').val();
    var agreement = $('#agreement').val();

    if ($('#DLN_zoneGroup_' + id).hasClass('openSizeType')) {
        $('#DLN_sizeTypeContainer').removeClass('d-none');
        $('#DLN_zonesContainer').addClass('d-none');
    } else {
        $('.DLN_sizeType_radio').prop('checked', false);
        $('#DLN_sizeTypeContainer').addClass('d-none');
        $('#DLN_zonesContainer').removeClass('d-none');
        getZones($(this).attr('data-url'), $(this).attr('data-method'), partner, customer, $(this).val(), agreement);
    }
});

body.on('click', '.DLN_sizeType_radio', function () {
    let partner = $('#partner').val();
    let customer = $('#customer').val();
    let agreement = $('#agreement').val();

    $('#DLN_zonesContainer').removeClass('d-none');
    getZones(
        $(this).attr('data-url'),
        $(this).attr('data-method'),
        partner,
        customer,
        $(this).attr('data-zone-group'),
        agreement,
        $(this).val()
    );
});
