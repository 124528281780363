body = $('body');


body.on('change', '#vlc_zone_1', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    let zone = $(this).val();
    let zoneGroup = $('#vlc_zone_option_' + $(this).val()).attr('data-zone-group')
    let session = $('#session').val();
    let selectedZone = 'zone_1';
    if ($(this).val()) {
        getVLCZone1(url, method, zone, zoneGroup, session, selectedZone);
    } else {
        $('#VLC_zone_info_wrapper_1').html('')
    }
});

function getVLCZone1(url, method, zone, zoneGroup, session, selectedZone) {
    let zone_1 = $('#vlc_zone_1').val();
    let zone_2 = $('#vlc_zone_2').val();
    let zone_3 = $('#vlc_zone_3').val();
    let unique = $('#unique').val();
    var agreement = $('#agreement').val();
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            selectedZone: selectedZone,
            session: session,
            zone: zone,
            zoneGroup: zoneGroup,
            zone_1: zone_1,
            zone_2: zone_2,
            zone_3: zone_3,
            agreement: agreement,
            unique: unique
        },
        success: function (data) {
            $('#VLC_zone_info_wrapper_1').html(data.html);
            KTImageInput.createInstances();
            if ($('#unique_zone_alert').length > 0 && $('#unique_zone_alert').attr('data-zone') === 'zone_2') {
                $('#vlc_zone_2').trigger('change');
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
            getVLCPrice();
        }
    });
}

body.on('change', '#vlc_zone_2', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    let zone = $(this).val();
    let zoneGroup = $('#vlc_zone_option_' + $(this).val()).attr('data-zone-group')
    let session = $('#session').val();
    let selectedZone = 'zone_2';
    if ($(this).val()) {
        getVLCZone2(url, method, zone, zoneGroup, session, selectedZone);
    } else {
        $('#VLC_zone_info_wrapper_2').html('')
    }
});

body.on('change', '#vlc_zone_3', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    let zone = $(this).val();
    let zoneGroup = $('#vlc_zone_option_' + $(this).val()).attr('data-zone-group')
    let session = $('#session').val();
    let selectedZone = 'zone_3';
    if ($(this).val()) {
        getVLCZone3(url, method, zone, zoneGroup, session, selectedZone);
    } else {
        $('#VLC_zone_info_wrapper_3').html('')
    }
});

function getVLCZone2(url, method, zone, zoneGroup, session, selectedZone) {
    let zone_1 = $('#vlc_zone_1').val();
    let zone_2 = $('#vlc_zone_2').val();
    let zone_3 = $('#vlc_zone_3').val();
    let unique = $('#unique').val();
    var agreement = $('#agreement').val();
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            selectedZone: selectedZone,
            session: session,
            zone: zone,
            zoneGroup: zoneGroup,
            zone_1: zone_1,
            zone_2: zone_2,
            zone_3: zone_3,
            agreement: agreement,
            unique: unique
        },
        success: function (data) {
            $('#VLC_zone_info_wrapper_2').html(data.html);
            KTImageInput.createInstances();
            if ($('#unique_zone_alert').length > 0 && $('#unique_zone_alert').attr('data-zone') === 'zone_1') {
                $('#vlc_zone_1').trigger('change');
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
            getVLCPrice();
        }
    });
}

function getVLCZone3(url, method, zone, zoneGroup, session, selectedZone) {
    let zone_1 = $('#vlc_zone_1').val();
    let zone_2 = $('#vlc_zone_2').val();
    let zone_3 = $('#vlc_zone_3').val();
    let unique = $('#unique').val();
    var agreement = $('#agreement').val();
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            selectedZone: selectedZone,
            session: session,
            zone: zone,
            zoneGroup: zoneGroup,
            zone_1: zone_1,
            zone_2: zone_2,
            zone_3: zone_3,
            agreement: agreement,
            unique: unique
        },
        success: function (data) {
            $('#VLC_zone_info_wrapper_3').html(data.html);
            KTImageInput.createInstances();
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
            getVLCPrice();
        }
    });
}

body.on('change', '.VLC_price_radio', function () {
    $('.VLC_price_free').prop('disabled', false);

    let zoneWrapper2 = $('#VLC_zone_2_wrapper');
    let zoneWrapper3 = $('#VLC_zone_3_wrapper');
    if (parseInt($(this).val()) === 1) {
        zoneWrapper2.addClass('d-none');
        zoneWrapper3.addClass('d-none');
    } else if (parseInt($(this).val()) === 2) {
        zoneWrapper2.removeClass('d-none');
        zoneWrapper3.addClass('d-none');
    } else {
        zoneWrapper2.removeClass('d-none');
        zoneWrapper3.removeClass('d-none');

        $('.VLC_price_free').prop('disabled', true).prop("checked", false);
        $('#VLC_price_free_0').prop('disabled', false).prop("checked", true);
    }

    getVLCPrice();
});

body.on('change', '.VLC_price_free', function () {

    let priceMethodWrapperEl = $('#price_method_wrapper');

    priceMethodWrapperEl.addClass('d-none');
    if (parseInt($(this).val()) !== 0 && parseInt($(this).val()) !== 2) {
        priceMethodWrapperEl.removeClass('d-none');
    } else {
        $('.VLC_price_method[value="0"]').prop("checked", true);
    }

    $('#LP_price_zone_2').prop("disabled", false);
    $('#LP_price_zone_3').prop("disabled", false);
    if (parseInt($(this).val()) === 2) {
        $('#LP_price_zone_1').prop("checked", true).trigger('change');
        $('#LP_price_zone_2').prop("disabled", true);
        $('#LP_price_zone_3').prop("disabled", true);
    }

    getVLCPrice()
});

body.on('change', '.VLC_price_method', function () {
    getVLCPrice();
});

function getVLCPrice() {
    let url = $('#price').attr('data-url');
    let method = $('#price').attr('data-method');
    let price = $('.VLC_price_radio:checked').val();
    let free = $('.VLC_price_free:checked').val();
    let priceMethod = $('.VLC_price_method:checked').val();
    let partner = $('#partner').val();

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            price_zone: price,
            price_free: free,
            price_method: priceMethod,
            zone_1: $('#vlc_zone_1').val(),
            zone_2: $('#vlc_zone_2').val(),
            zone_3: $('#vlc_zone_3').val(),
            partner: partner
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#price').val(data.price);
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}
