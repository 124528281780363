import Swal from "sweetalert2";
import printJS from "print-js";
import html2canvas from "html2canvas";

window.body = $('body');


/* PRODUCTS */
body.on('click', '.add_product_session', function () {
    let product_id = $(this).attr('data-product');
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    addProductModal(url, method, product_id);
});

function addProductModal(url, method, product_id) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            product_id: product_id
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#add_session_product_id').val(data.id);
                $('#add_session_product_warning').addClass('d-none');
                $('#add_session_product_quantity').attr('disabled', true).attr('max', data.stock).val(0);
                $('#add_session_product_price, #add_session_product_total').val(data.price);
                $('#add_session_product_submit').addClass('d-none');
                $('.product_promo_input_wrappers').addClass('d-none');
                if (data.hasProductPromoPermission) {
                    $('.product_promo_input_wrappers').removeClass('d-none');
                }
                if (data.stock > 0) {
                    $('#add_session_product_quantity').val(1).attr('disabled', false);
                    $('#add_session_product_submit').removeClass('d-none');
                } else {
                    $('#add_session_product_warning').removeClass('d-none');
                }
                $('#add_session_product_modal').modal('show');
            } else {

            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        }
    });
}

body.on('change', 'input#add_session_product_quantity', function () {
    let quantity = parseInt($(this).val());
    let price = $('#add_session_product_price').val();
    price = getFloatFromMoney(price, '€');
    let total = price * quantity;
    $('#add_session_product_total').val(number_format(total, 2, '.', '') + '€');
});

body.on('change', '#add_session_product_promo_price', function () {
    if ($(this).is(':checked')) {
        $('#add_session_product_price').removeAttr('disabled').focus();
        $('#add_session_product_promo_free').prop('checked', false);
    } else {
        $('#add_session_product_price').attr('disabled', 'disabled');
    }
});
body.on('change', '#add_session_product_promo_free', function () {
    if ($(this).is(':checked')) {
        $('#add_session_product_promo_price').prop('checked', false);
        $('#add_session_product_price').prop('disabled', true).val('0.00€');
    } else {
        $('#add_session_product_price').prop('disabled', false);
    }
})

body.on('keyup', '#add_session_product_price', function () {
    $(this).val(number_format($(this).val(), 2, '.', '') + '€');
    let quantity = parseInt($('#add_session_product_quantity').val());
    let price = $(this).val();
    price = getFloatFromMoney(price, '€');
    let total = price * quantity;
    $('#add_session_product_total').val(number_format(total, 2, '.', '') + '€');
});

body.on('click', '#add_session_product_submit', function () {
    var product = $('input#add_session_product_id').val();
    var quantity = $('input#add_session_product_quantity').val();
    var promo_price = $('input#add_session_product_promo_price').is(':checked');
    var promo_free = $('input#add_session_product_promo_free').is(':checked');
    var price = getFloatFromMoney($('input#add_session_product_price').val(), '€');
    let formData = new FormData($('#form_session').get(0));
    formData.append('product', product);
    formData.append('quantity', quantity);
    formData.append('promo_price', promo_price);
    formData.append('promo_free', promo_free);
    formData.append('price', price);
    var url = $(this).attr('data-url');
    var method = $(this).attr('data-method');
    addProductSession(url, method, formData);
});

body.on('click', '.delete_product_session', function () {
    var id = $(this).attr('data-id');
    var url = $(this).attr('data-url');
    var method = $(this).attr('data-method');
    removeProductSession(url, method, id);
});

function addProductSession(url, method, formData) {
    window.blockUIsessionProductModal = new KTBlockUI(document.querySelector('body #add_session_product_form'));
    blockUIsessionProductModal.block();

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        processData: false,
        contentType: false,
        data: formData,
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#newServiceProductsDatatable').DataTable().destroy();
                $('#table_products_tr_' + data.remove).remove();
                $('#table_products_added_tbody').append(data.add);
                $('input#session').val(data.session);
                $('input#referral_session').val(data.session);
                $('#newServiceProductsDatatable').DataTable();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            blockUIsessionProductModal.release();
            blockUIsessionProductModal.destroy();
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
            $('.modal').modal('hide');
        }
    });
}

function removeProductSession(url, method, id) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            id: id
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#newServiceProductsDatatable').DataTable().destroy();
                $('#session_product_' + data.remove).remove();
                $('#products_list').append(data.add);
                $('#newServiceProductsDatatable').DataTable();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}


/* ZONES */

window.getZones = function getZones(url, method, partner, customer, size, agreement, group = null, freeSessions = 0) {
    let except = [];
    $('#added_zones_table tbody tr').each(function () {
        except.push($(this).attr('data-id'));
    });

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            partner: partner,
            customer: customer,
            size: size,
            agreement: agreement,
            group: group,
            except: except,
            freeSessions: freeSessions
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('table#zone_list tbody').html(data.update);
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

window.getZoneModal = function getZoneModal(url, method) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            phototype_id: $('input[name="phototype_id"]:checked').val()
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#add_session_zone_price_id').val(data.id);
                $('#add_session_zone_shoots_label').html('Disparos (' + data.min + '-' + data.max + ')');
                $('#add_session_zone_shoots').attr('min', data.min).attr('max', 9999999).val('');
                let joulesInput = $('#add_session_zone_joules');
                if (joulesInput.length > 0) {
                    joulesInput.val('');
                }
                if (data.phototypeTable) {
                    $('#add_session_zone_phototype_table').html(data.phototypeTable);
                }
                $('#add_session_zone_name').html(data.label);
                $('#add_session_zone').modal('show');
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

body.on('click', '#add_session_zone_submit', function () {
    submitZoneModal($(this).attr('data-url'), $(this).attr('data-method'), $('input#session').val(), $('#agreement').val());
});

window.submitZoneModal = function submitZoneModal(url, method, session, agreement) {
    window.blockUIsessionZoneModal = new KTBlockUI(document.querySelector('body #add_session_zone .modal-content'));
    blockUIsessionZoneModal.block();

    var formData = new FormData();
    $('#add_session_zone input').each(function () {
        formData.append($(this).attr('name'), $(this).val());
    });
    $('#add_session_zone textarea').each(function () {
        formData.append($(this).attr('name'), $(this).val());
    });
    formData.append('session', session);
    formData.append('agreement', agreement);
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        processData: false,
        contentType: false,
        data: formData,
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#added_zones_table').DataTable().destroy();
                $('input#session').val(data.session);
                $('#add_session_zone').modal('hide');
                $('#added_zones_table tbody').append(data.add)
                $('#price_row_' + data.remove).remove();
                $('#add_session_zone_form').trigger('reset');
                $('#added_zones_table').DataTable();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                console.log(err);
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
            blockUIsessionZoneModal.release();
            blockUIsessionZoneModal.destroy();
        }
    });
}

body.on('click', '.zone_delete_btn', function () {
    deleteSessionZones($(this).attr('data-url'), $(this).attr('data-method'), $(this).attr('data-session'), $(this).attr('data-id'));
});

window.deleteSessionZones = function deleteSessionZones(url, method, session, id) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            session: session,
            id: id
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#added_zones_table').DataTable().destroy();
                $('#zone_row_' + data.remove).remove();
                if ($(".sizeType_radio:checked").val()) {
                    $('.sizeType_radio').click();
                } else {
                    $('.zoneGroup_radio:checked').click();
                }
                $('#added_zones_table').DataTable();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

/* Sessions */
body.on('click', '.agreement_new_session', function () {
    addNewSessionView($(this).attr('data-url'), $(this).attr('data-method'));
});

window.addNewSessionView = function addNewSessionView(url, method) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {},
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#session-container').html(data.html);
                $('html, body').animate({
                    scrollTop: $('#session-container').offset().top
                });
                KTImageInput.createInstances();
                imageClicker('image_unhas');
                AnyPickerInit('Data de Sessão');
                initSelect2Ajax();
                $('#agreement-container').addClass('d-none');
                $('#newServiceProductsDatatable').DataTable();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

body.on('click', '.session_details_btn', function () {
    viewSessionDetails($(this).attr('data-url'), $(this).attr('data-method'), $(this).attr('data-id'));
});

window.viewSessionDetails = function viewSessionDetails(url, method, session) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {},
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#agreement-container').addClass('d-none');
                $('#session-container').html(data.html);
                $('html, body').animate({
                    scrollTop: $('#session-container').offset().top
                });
                if ($('#added_zones_table').length > 0) {
                    $('#added_zones_table').DataTable();
                }
                $('#newServiceProductsDatatable').DataTable();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

body.on('click', '.session_delete_btn', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    $('#session-container').html('');
    deleteSession(url, method);
});

body.on('click', '.session_discount_delete_btn', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    $('#session-container').html('');
    updateDiscountSession(url, method, {});
});

body.on('click', '.session_discount_update_btn', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    let formEl = $('#session_discount_update_form');
    let modalEl = $('#session_discount_update_modal');
    let discountInput = $('#session_discount_update_discount');
    window.activateMaskers();

    discountInput.val($(this).attr('data-discount'));
    formEl.attr('action', url);
    formEl.attr('method', method);

    modalEl.modal('show');
})

body.on('click', '.session_number_update_btn', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    let formEl = $('#session_number_update_form');
    let modalEl = $('#session_number_update_modal');
    let numberInput = $('#session_number_update_discount');

    numberInput.val($(this).attr('data-discount'));
    formEl.attr('action', url);
    formEl.attr('method', method);

    modalEl.modal('show');
})

body.on('click', '.session_price_update_btn', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    let formEl = $('#session_price_update_form');
    let modalEl = $('#session_price_update_modal');
    let numberInput = $('#session_price_update_price');

    numberInput.val($(this).attr('data-price'));
    formEl.attr('action', url);
    formEl.attr('method', method);

    modalEl.modal('show');
})

body.on('click', '.session-view-session-products', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {},
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                let modalEl = $('#session-view-session-products-modal');

                $(modalEl).find('.modal-body').html(data.html);
                window.activateMaskers();

                modalEl.modal('show');
            }
        },
        error: function (err) {
            console.log('ERROR OPENING PRODUCTS MODAL');
        }
    })
})

body.on('submit', '#session_discount_update_form', function (e) {
    e.preventDefault();
    let modalEl = $('#session_discount_update_modal');
    let data = new FormData($(this).get(0))
    let url = $(this).attr('action');
    let method = $(this).attr('method');

    modalEl.modal('hide');

    updateDiscountSession(url, method, data);
});

body.on('submit', '#session_price_update_form', function (e) {
    e.preventDefault();
    let modalEl = $('#session_price_update_modal');
    let data = new FormData($(this).get(0))
    let url = $(this).attr('action');
    let method = $(this).attr('method');

    modalEl.modal('hide');

    updatePriceSession(url, method, data);
});

body.on('submit', '#session_number_update_form', function (e) {
    e.preventDefault();
    let modalEl = $('#session_number_update_modal');
    let data = new FormData($(this).get(0))
    let url = $(this).attr('action');
    let method = $(this).attr('method');

    modalEl.modal('hide');

    updateNumberSession(url, method, data);
});

window.deleteSession = function deleteSession(url, method) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            '_method': 'DELETE'
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#session-container').html('');
                $('#customerAgreementSessionsDatatable_' + data.form_type).DataTable().ajax.reload();
                $('#session_buttons_wrapper').addClass('d-none');
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

window.updateDiscountSession = function updateDiscountSession(url, method, data) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        processData: false,
        contentType: false,
        data: data,
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#session-container').html('');
                $('#customerAgreementSessionsDatatable_' + data.form_type).DataTable().ajax.reload();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

window.updatePriceSession = function updatePriceSession(url, method, data) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        processData: false,
        contentType: false,
        data: data,
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#session-container').html('');
                $('#customerAgreementSessionsDatatable_' + data.form_type).DataTable().ajax.reload();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

window.updateNumberSession = function updateDiscountSession(url, method, data) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        processData: false,
        contentType: false,
        data: data,
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#session-container').html('');
                $('#customerAgreementSessionsDatatable_' + data.form_type).DataTable().ajax.reload();
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Este número de sessão já existe. Altere para um número diferente.'
                }).then(r => {
                    $('#session_number_update_modal').modal('show');
                });
            }
        },
        error: function (err) {
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Este número de sessão já existe. Altere para um número diferente.'
            }).then(r => {
                $('#session_number_update_modal').modal('show');
            });
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

body.on('click', '.session_edit_btn', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    viewSessionEdit(url, method);
});

window.viewSessionEdit = function deleteSession(url, method, scrollTo = null) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {},
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#session-container').html(data.html);
                $('html, body').animate({
                    scrollTop: $('#session-container').offset().top
                });
                KTImageInput.createInstances();
                imageClicker('image_unhas');
                $('#newServiceProductsDatatable').DataTable();
                if ($('#added_zones_table').length > 0) {
                    $('#added_zones_table').DataTable();
                }
                AnyPickerInit('Data de Sessão');
                initSelect2Ajax();
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

body.on('click', '.session_finish_btn', function () {
    if ($(this).attr('data-kt-indicator') !== 'on') {
        viewSessionEdit($(this).attr('data-url'), $(this).attr('data-method'), 'session_date');
    }
});

function noProductsAlert(url, method, agreementId, sessionId) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            agreement_id: agreementId,
            session_id: sessionId
        },
        success: function (data) {
            let finishSessionBtn = $('.session_finish');
            if (data.status) {
                redWarningModal(null, data.html, '', data.time);
            }

            finishSessionBtn.attr('data-ignore-products', 1);
            finishSessionBtn.attr('data-kt-indicator', 'off');

            if (data.status === false) {
                finishSessionBtn.click();
            }
        }
    });
}

body.on('click', '.session_finish', async function () {
    let formWrapper = $('#form_session');

    if ($('#table_products_added_tbody tr:not(:has(td.dataTables_empty))').length === 0 && parseInt($(this).attr('data-ignore-products')) === 0) {
        noProductsAlert(
            $(this).attr('data-no-product-url'),
            $(this).attr('data-no-product-method'),
            $('#agreement').val(),
            $('#session').val()
        );

        return;
    }

    window.blockUIformFinish = new KTBlockUI(document.querySelector('body #form_session'));
    blockUIformFinish.block();

    let disabled_inputs = [];
    $('body input:disabled').each(function () {
        disabled_inputs.push($(this));
        $(this).prop('disabled', false);
    });
    let formData = new FormData(formWrapper.get(0));
    disabled_inputs.forEach(function (item, index) {
        item.prop('disabled', true);
    });

    /* Image Clicker Canvas */
    let imageClickerEl = $('body #imageClicker_html_wrapper');
    if (imageClickerEl.length > 0) {
        await html2canvas(imageClickerEl[0]).then((canvas) => {
            formData.set('selected_locations_image', canvas.toDataURL('image/png'));
            return canvas;
        });
    }

    sessionFinish($(this).attr('data-url'), $(this).attr('data-method'), formData, blockUIformFinish);
});

window.sessionFinish = function sessionFinish(url, method, formData, blockUI = null) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        processData: false,
        contentType: false,
        data: formData,
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                $('#session-container').html('');
                $('#customerAgreementSessionsDatatable_' + data.form_type).DataTable().ajax.reload();
            }
        },
        error: function (err) {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
            $('.ajaxFormError').remove();
            if (err.responseJSON.session_id !== undefined) {
                $('#form_session input#session').val(err.responseJSON.session_id);
            }
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                window.formNotify('update', false, 'sessions', 5000);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
            if (blockUI !== null) {
                blockUI.release();
                blockUI.destroy();
            }
        }
    });
}

function redWarningModal(inputFile, content, type = 'image', totalTime = 5000) {
    if (content !== undefined && content !== '') {
        Swal.fire({
            "title": "ATENÇÃO",
            "html": content,
            "type": "warning",
            "confirmButtonClass": "btn btn-success",
            "cancelButtonClass": "btn btn-danger",
            "showCancelButton": false,
            "confirmButtonText": 'Continuar',
            "cancelButtonText": "Voltar",
            customClass: {
                container: 'text-white',
                popup: 'bg-danger w-100 h-100',
                header: '',
                title: 'text-white swal-title-custom',
                closeButton: 'your-close-button-class',
                icon: 'border-white text-white',
                content: 'text-white swal-content-custom',
                input: 'your-input-class',
                actions: 'your-actions-class',
                confirmButton: 'your-confirm-button-class',
                cancelButton: 'your-cancel-button-class',
                footer: 'your-footer-class'
            },
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
                setTimeout(() => {
                    Swal.hideLoading()
                }, totalTime)
            },
            willClose: () => {
                Swal.hideLoading();
            }
        }).then(
            function (isConfirm) {
                if (isConfirm.value === true) {
                    if (type === 'image') {
                        inputFile.click();
                    }
                    if (type === 'zoneModal') {
                        getZoneModal(inputFile.attr('data-url'), inputFile.attr('data-method'));
                    }
                    Swal.close();
                }
            })
    } else {
        if (type === 'image') {
            inputFile.click();
        }
        if (type === 'zoneModal') {
            getZoneModal(inputFile.attr('data-url'), inputFile.attr('data-method'));
        }
    }
}


body.on('click', '.btn_image_modal', function () {
    let el = $(this);
    let formType = $('input#service_type').val();
    let inputFile = el.parent().find('input[type="file"]');
    let html = window.language['imagePicker']['default']
    if (formType !== '' && formType !== null) {
        html = window.language['imagePicker'][formType]
    }
    redWarningModal(inputFile, html);
});

body.on('click', '.zone_modal_btn', function () {
    let inputFile = $(this);
    let formType = $('input#service_type').val();
    let content = '';
    let possibleNumber = $('input#possible_number').val();
    let phototypeEl = $('input[name="phototype_id"]');
    let hasPhototypeEl = phototypeEl.length > 0;
    let checkedPhototypeEl = $('input[name="phototype_id"]:checked');
    let phototypeId = checkedPhototypeEl.val() !== undefined ? checkedPhototypeEl.val() : null;
    if (hasPhototypeEl && phototypeId === null) {
        Swal.fire({
            title: 'Erro',
            text: 'Selecione um fototipo antes de continuar.',
            icon: 'error'
        });
        return;
    }

    if (formType === 'DL') {
        content = window.language['imagePicker']['DL_session_' + possibleNumber];
    }
    if (formType === 'DLN') {
        content = window.language['imagePicker']['DLN_session_' + possibleNumber];
    }
    redWarningModal(inputFile, content, 'zoneModal');
});

/* Just adding others for easier import */
require('./DL');
require('./SQE');
require('./LV');
require('./VLC');
require('./DLVLC');
require('./DLN');
require('./FRVLF');
require('./AI');
require('./TAT');
require('./DL2025');
require('./LP');


/* Printing Sessions */
global.printCurrentSession = function printCurrentSession() {
    let cssArr = [];
    $('link[rel="stylesheet"]').each(function () {
        cssArr.push($(this).attr('href'));
    });
    console.log(cssArr);
    printJS({
        printable: 'form_session_body',
        type: 'html',
        css: cssArr
    })
}

body.on('click', '#printCurrentSessionDetails', function () {
    printCurrentSession();
});


body.on('change', 'input[name="user_rating"]', function () {
    const userRatingObservationsWrapper = $('#user_rating_observations_wrapper');
    const userRatingWarningWrapper = $('#user_rating_warning_wrapper');
    const val = parseInt($(this).val());

    $(this).parent().find('#rating-label').html(window.language['internalRating'][val]);

    userRatingWarningWrapper.removeClass('d-none');

    if (val <= 2) {
        userRatingObservationsWrapper.removeClass('d-none');
    } else {
        userRatingObservationsWrapper.addClass('d-none');
    }

})

body.on('change', '.session_other_associated_services', function () {
    const val = parseInt($(this).val());
    const isChecking = $(this).is(':checked');

    if (isChecking) {
        if (val === 0) {
            $('.session_other_associated_services').prop('checked', false);
            $('.session_other_associated_services[value="0"]').prop('checked', true);
        } else {
            $('.session_other_associated_services[value="0"]').prop('checked', false);
        }
    } else {
        if ($('.session_other_associated_services:checked').length === 0) {
            $('.session_other_associated_services[value="0"]').prop('checked', true);
        }
    }

    let html = '';
    $('.session_other_associated_services:checked').each(function (i, e) {
        html += '<span class="badge badge-info badge-md mb-2" style="width: max-content">' + $(e).attr('data-title') + '</span>';
    });

    $('#session_other_associated_services_tag_wrapper').html(html);
});
