

$('body').on('change', '#userStockAdd_select', function () {
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    let product = $(this).val();
    $('#userStockAdd_quantity_container').addClass('d-none');
    $('#userStockAdd_quantity').val(0);
    $('#userStockAddProductContainer').html('');

    if (product !== '') {
        $.ajax({
            url: url,
            type: method,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            data: {
                product: product
            },
            success: function (data) {
                $('#userStockAddProductContainer').html(data.html);
                $('#userStockAdd_quantity_container').removeClass('d-none');
            },
            error: function (err) {
                $('.ajaxFormError').remove();
                if (err.status == 422) { // when status code is 422, it's a validation issue

                    $('#success_message').fadeIn().html(err.responseJSON.message);
                    // display errors on each form field
                    $.each(err.responseJSON.errors, function (i, error) {
                        var el = $(document).find('[name="'+i+'"]');
                        //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                        el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                        //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                    });
                }
            }
        });
    }
})

$('body').on('change', '#userStockAdd_quantity', function () {
    adjustStockCard()
});

$('body').on('click', '#userStockAdd_quantity_decrease, #userStockAdd_quantity_increase', function() {
    adjustStockCard()
});

function adjustStockCard() {
    console.log('adjusting..');

    let currentStock = $('#userStockAddCounter').attr('data-current');
    let adjust = parseInt($('#userStockAdd_quantity').val());

    let total = parseInt(currentStock) + adjust;
    $('#userStockAdd_newStock').html(total);

    if(adjust < 0) {
        $('#userStockAddCounter').html(adjust).removeClass('text-dark').removeClass('text-success').addClass('text-danger');
    } else if(adjust > 0) {
        $('#userStockAddCounter').html('+'+adjust).removeClass('text-dark').removeClass('text-danger').addClass('text-success');
    } else {
        $('#userStockAddCounter').html(0).removeClass('text-danger').removeClass('text-success').addClass('text-dark');
    }
}

$('body').on('click', '.userStock_modal_btn', function () {
    let value = $(this).attr('data-value');
    console.log(value);
    $('#userStockAdd_select').val(value).trigger('change');
    $('#addUserStockModal').modal('show');
});
