body = $('body');

body.on('change', '.FRVLF_type_input', function () {
    let sessionType = $('.FRVLF_type_input:checked').val();
    console.log(sessionType);
    $('.FRVLF_type_input').each(function (i, v) {
        $('#FRVLF_type_' + $(v).val() + '_wrapper').addClass('d-none');
    });

    $('#FRVLF_type_' + sessionType + '_wrapper').removeClass('d-none');
});
