body.on('click', '.LV_zoneGroup_radio', function () {
    id = $(this).val();

    var partner = $('#partner').val();
    var customer = $('#customer').val();
    var agreement = $('#agreement').val();

    $('.LV_sizeType_radio').prop('checked', false);
    $('#LV_sizeTypeContainer').addClass('d-none');
    $('#LV_zonesContainer').removeClass('d-none');
    getZones($(this).attr('data-url'), $(this).attr('data-method'), partner, customer, null, agreement, $(this).val());
});
