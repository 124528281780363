window.customerAgreementSessionsDatatable_TAT = function customerAgreementSessionsDatatable_TAT() {
    url = $('#customerAgreementSessionsDatatable_TAT').attr('data-url');
    dt = $("#customerAgreementSessionsDatatable_TAT").DataTable({
        searchDelay: 500,
        order: [[0, 'asc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'selected'
        },
        columns: [
            {data: 'number'},
            {data: 'shoots'},
            {data: 'finished'},
            {data: 'confirmed'},
            {data: 'confirmed_date'},
            {data: 'session_date'},
            {data: 'price'},
            {data: 'discount'},
            {data: 'products'},
            {data: 'observations'},
            {data: 'actions'},

            {data: 'itemID'},
            {data: 'isConfirmed'},
            {data: 'isFinished'},
            {data: 'btn_details'},
            {data: 'btn_edit'},
            {data: 'btn_delete'}
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: true
            },
            {
                targets: 4,
                orderable: true
            },
            {
                targets: 5,
                orderable: true
            },
            {
                targets: 6,
                orderable: true
            },
            {
                targets: 7,
                orderable: true
            },
            {
                targets: 8,
                orderable: true
            },
            {
                targets: 9,
                orderable: true
            },
            {
                targets: 10,
                orderable: true
            },
            {
                targets: 11,
                orderable: false,
                visible: false,
                searchable: false
            },
            {
                targets: 12,
                orderable: false,
                visible: false,
                searchable: false
            },
            {
                targets: 13,
                orderable: false,
                visible: false,
                searchable: false
            },
            {
                targets: 14,
                orderable: false,
                visible: false,
                searchable: false
            },
            {
                targets: 15,
                orderable: false,
                visible: false,
                searchable: false
            },
            {
                targets: 16,
                orderable: false,
                visible: false,
                searchable: false
            },
        ]
    });

    const filterSearch = document.querySelector('[data-kt-user-table-filter-customerAgreementSessionsDatatable_TAT="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });

    dt.on('select', function (e, dt_, type, indexes) {
        var btn_edit = $('#session_btn_edit');
        var btn_delete = $('#session_btn_delete');
        var btn_details = $('#session_btn_details');
        var btn_wrapper = $('#session_buttons_wrapper');
        var data = dt_.rows(".selected").data();

        btn_details.attr('data-url', data[0].btn_details).attr('data-id', data[0].itemID);
        btn_edit.attr('data-url', data[0].btn_edit).attr('data-id', data[0].itemID);
        if (btn_delete.length > 0) {
            btn_delete.attr('data-url', data[0].btn_delete).attr('data-id', data[0].itemID);
        }

        btn_edit.addClass('d-none');
        btn_delete.addClass('d-none');
        if (parseInt(data[0].isConfirmed) === 0) {
            btn_edit.removeClass('d-none');
            btn_delete.removeClass('d-none');
        }

        btn_wrapper.removeClass('d-none')

        $('html, body').animate({
            scrollTop: btn_wrapper.offset().top
        });
    });
}
