$('body').on('change', '.customer-referral-form-select input', function () {
    $('body .customer-referral-form-select').addClass('d-none');
    if ($(this).val() === 'referrer') {
        $('body .customer-referral-form-referrer').removeClass('d-none');
    } else {
        $('body .customer-referral-form-referral').removeClass('d-none');
    }
});

$('body').on('click', '.initNewReferralModal', function () {
    $('body .customer-referral-form-select').removeClass('d-none');
    $('body .customer-referral-form-referrer').addClass('d-none');
    $('body .customer-referral-form-referral').addClass('d-none');
    $(this).find('form').trigger('reset');
    $('#newReferralModal').modal('show');
})
