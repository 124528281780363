import Swal from 'sweetalert2';

window.portugueseStrToDate = function portugueseStrToDate(dtStr) {
    if (!dtStr) return null
    let dateParts = dtStr.split("-");
    dateParts[2] = dateParts[2].split(" ")[0];
    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
}

window.englishStrToDate = function englishStrToDate(dtStr) {
    if (!dtStr) return null
    let dateParts = dtStr.split("-");
    dateParts[0] = dateParts[0].split(" ")[0];
    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2]);
}

window.englishStrToDatePlusOneDay = function englishStrToDatePlusOneDay(dtStr) {
    if (!dtStr) return null
    let dateParts = dtStr.split("-");
    dateParts[0] = dateParts[0].split(" ")[0];
    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);
}

window.AnyPickerInit = function (title = 'Data') {
    let currentDate = new Date();


    $("body input[data-type='date']").each(function () {
        let elVal = $(this).val();
        let defaultValue = currentDate;
        let maxDate = englishStrToDatePlusOneDay(new Date().toISOString().slice(0, 10))
        if (elVal !== '') {
            defaultValue = portugueseStrToDate(elVal);
        }

        $(this).AnyPicker({
            mode: "datetime",
            dateTimeFormat: "dd-MM-yyyy",
            lang: "pt",
            minValue: new Date(1920, 1, 1),
            maxValue: maxDate,
            selectedDate: defaultValue,
            theme: "iOS",
            i18n: {
                headerTitle: title
            }
        });
    });

    $("body input[data-type='date-no-limit']").each(function () {
        let elVal = $(this).val();
        let defaultValue = currentDate;
        if (elVal !== '') {
            defaultValue = portugueseStrToDate(elVal);
        }
        $(this).AnyPicker({
            mode: "datetime",
            dateTimeFormat: "dd-MM-yyyy",
            lang: "pt",
            minValue: new Date(1920, 1, 1),
            selectedDate: defaultValue,
            theme: "iOS",
            i18n: {
                headerTitle: title
            }
        });
    });

    $("body input[data-type='date-max-1-year']").each(function () {
        let elVal = $(this).val();
        let today = currentDate;
        if (elVal !== '') {
            today = portugueseStrToDate(elVal);
        }

        let maxValue = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
        let minValue = new Date(1920, 1, 1)

        $(this).AnyPicker({
            mode: "datetime",
            dateTimeFormat: "dd-MM-yyyy",
            lang: "pt",
            minValue: minValue,
            maxValue: maxValue,
            selectedDate: today,
            theme: "iOS",
            i18n: {
                headerTitle: title
            }
        });
    });

    $("body input[data-type='date-next-session']").each(function () {
        let elVal = $(this).val();
        let defaultValue = currentDate;
        if (elVal !== '') {
            defaultValue = portugueseStrToDate(elVal);
        }

        let maxValue = new Date(currentDate.getFullYear() + 1, 11, 31);

        $(this).AnyPicker({
            mode: "datetime",
            dateTimeFormat: "dd-MM-yyyy",
            lang: "pt",
            minValue: new Date(currentDate.getFullYear(), 0, 1),
            maxValue: maxValue,
            selectedDate: defaultValue,
            theme: "iOS",
            i18n: {
                headerTitle: title
            }
        });
    });

    $("body input[data-type='date-session-date']").each(function () {
        let elVal = $(this).val();
        let defaultValue = new Date();
        if (elVal !== '') {
            defaultValue = portugueseStrToDate(elVal);
        }

        let minValue = new Date(currentDate.getFullYear() - 1, 0, 1);

        $(this).AnyPicker({
            mode: "datetime",
            dateTimeFormat: "dd-MM-yyyy",
            lang: "pt",
            minValue: minValue,
            selectedDate: defaultValue,
            theme: "iOS",
            i18n: {
                headerTitle: title
            }
        });
    });
}
AnyPickerInit();


window.submitForm = function submitForm(url, method, action, formData, type, update, notify_time = 5000, isCalendar = false, formid = '') {
    if (formid !== '') {
        var submitFormBlock = new KTBlockUI(document.querySelector('body #' + formid));
        submitFormBlock.block();
    }

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        processData: false,
        contentType: false,
        data: formData,
        success: function (data) {
            $('.ajaxFormError').remove();
            if (data.status === true || data.status === 'true') {
                if (data.update !== false) {
                    console.log(isCalendar);
                    if (isCalendar === true) {
                        //window.appointments.fullCalendar('refetchEvents');
                        $('#edit-appointment').modal('hide');
                    } else {
                        $('#' + data.update).html(data.html);
                    }
                }
                if (data.update2 !== false) {
                    console.log(isCalendar);
                    if (isCalendar === true) {
                        //window.appointments.fullCalendar('refetchEvents');
                        $('#edit-appointment').modal('hide');
                    } else {
                        $('#' + data.update2).html(data.html2);
                    }
                }
                if (data.update3 !== false) {
                    console.log(isCalendar);
                    if (isCalendar === true) {
                        //window.appointments.fullCalendar('refetchEvents');
                        $('#edit-appointment').modal('hide');
                    } else {
                        $('#' + data.update3).html(data.html3);
                    }
                }
                if (data.newCustomerService !== false && data.newCustomerService) {
                    window['customerAgreementSessionsDatatable_' + data.newCustomerService]();
                    KTImageInput.createInstances();
                    imageClicker('image_unhas');
                    AnyPickerInit();
                }
                if (data.scroll !== false && data.scroll) {
                    $('html, body').animate({
                        scrollTop: $('#' + data.scroll).offset().top
                    });
                }
                window.formNotify(action, data.status, type, notify_time);
                if (data.redirect) {
                    setTimeout(function () {
                        if (data.redirect === 'refresh') {
                            location.reload();
                        } else if (data.redirect !== '') {
                            window.location.href = data.redirect;
                        }
                    }, notify_time);
                }
                if (data.datatable && data.datatable !== '') {
                    $('#' + data.datatable).DataTable().ajax.reload();
                }
                if (data.datatable2 && data.datatable2 !== '') {
                    $('#' + data.datatable2).DataTable().ajax.reload();
                }
            } else {
                window.formNotify(action, false, type, notify_time);
            }
            if (data.status === true) {
                $('.modal').modal('hide');
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    let el = $(document).find('[name="' + i + '"]');
                    if (el.length === 0) {
                        el = $(document).find('#' + i);
                    }

                    $.notify({
                        icon: 'las la-exclamation-circle',
                        title: "<h4 class='mb-1 text-dark'>Erro</h4>",
                        message: 'Ocorreu um erro ao enviar o formulário. Por favor, verifique os erros acima e tente novamente.'
                    }, {
                        element: 'body',
                        type: 'danger',
                        animate: {
                            enter: 'animated flipInY',
                            exit: 'animated flipOutY'
                        },
                        placement: {
                            from: 'bottom',
                            align: 'right'
                        },
                        delay: notify_time
                    });

                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
            if (formid !== '') {
                submitFormBlock.release();
                submitFormBlock.destroy();
            }
        }
    });
}

window.swalFormAction = function swalFormAction(action, type, url, method, formData, update, notifyTime = 2500, hasCalendar = false, formid = '') {
    if (action === 'update' || action === 'delete') {
        status = 'warning';

        let notificationParams = window.getNotifyParams(action, status, type);
        Swal.fire({
            icon: 'warning',
            title: notificationParams.title,
            text: notificationParams.description,
            showCancelButton: true,
            showCloseButton: false,
            confirmButtonText: notificationParams.button,
            cancelButtonText: notificationParams.cancel
        }).then((result) => {
            if (result.isConfirmed) {
                submitForm(url, method, action, formData, type, update, notifyTime, hasCalendar, formid);
            }
        });
    } else {
        submitForm(url, method, action, formData, type, update, notifyTime, hasCalendar, formid);
    }
}

$('body').on('submit', '.ajax', function (e) {
    e.preventDefault();
    console.log($(this));
    let url = $(this).attr('action');
    let method = $(this).attr('method');
    let action = $(this).attr('data-action');
    let type = $(this).attr('data-type');
    let update = $(this).attr('data-update');
    let formData = new FormData($(this).get(0));
    $('.signature').each(function () {
        formData.append($(this).attr('data-name'), getSignatureData($(this).attr('id')));
        formData.append($(this).attr('data-name') + '_png', getSignatureData($(this).attr('id'), 'image'));
    });
    let hasCalendar = $(this).attr('data-calendar') === 'true';
    swalFormAction(action, type, url, method, formData, update, 2500, hasCalendar, $(this).attr('id'));
});

window.getNotifyParams = function getNotifyParams(type, status, page_type) {
    let notifyObj = $('#notificationData');

    console.log(type, status);
    if (status === true || status === 'true') {
        status = 'success';
    }
    if (status === false || status === 'false') {
        status = 'error';
    }

    let notifyParams = {
        title: "<h4 class='mb-1 text-dark'>" + window.language[page_type]['notifications'][type][status]['title'] + "</h4>",
        description: window.language[page_type]['notifications'][type][status]['description'],
        button: window.language[page_type]['notifications'][type][status]['button'] ?? '',
        cancel: window.language['base']['cancel']
    };

    if (status === 'success') {
        notifyParams.icon = 'las la-check-circle';
        notifyParams.type = 'success';
    } else {
        notifyParams.icon = 'las la-exclamation-circle';
        notifyParams.type = 'danger';
    }

    return notifyParams;
}

window.formNotify = function formNotify(type, status, page_type, notify_time = 5000) {
    if (status !== false) {
        status = 'success';
    }
    let params = getNotifyParams(type, status, page_type);
    $.notify({
        icon: params.icon,
        title: params.title,
        message: params.description
    }, {
        element: 'body',
        type: params.type,
        animate: {
            enter: 'animated flipInY',
            exit: 'animated flipOutY'
        },
        placement: {
            from: 'bottom',
            align: 'right'
        },
        delay: notify_time
    });
}

$('body').on('click', '.modal_close_btn', function () {
    $('body .modal').modal('hide');
});
