"use strict";
import Swal from "sweetalert2";
//
// SweetAlert2 Initialization
//

// Set Defaults
Swal.mixin({
	width: 400,
	heightAuto: false,
	padding: '2.5rem',
	buttonsStyling: false,
	confirmButtonClass: 'btn btn-success',
	confirmButtonColor: null,
	cancelButtonClass: 'btn btn-secondary',
	cancelButtonColor: null
});
