if($('#userPartnersAddDatatable').length > 0) {
    url = $('#userPartnersAddDatatable').attr('data-url');
    dt2 = $("#userPartnersAddDatatable").DataTable({
        searchDelay: 2000,
        order: [[0, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data: 'label'},
            {data: 'owner'},
            {data: 'partner_type'},
            {data: 'status'},
            {data: 'actions'},
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true,

            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: true
            },
            {
                searchable: false,
                targets: 4,
                orderable: false
            }
        ]
    });

    const filterSearch2 = document.querySelector('[data-kt-table-filter-userpartnersadddatatable="search"]');
    filterSearch2.addEventListener('keyup', function (e) {
        dt2.search(e.target.value).draw();
    });
}
