window.datatableLanguage = {
    "emptyTable": "Não foi encontrado nenhum registo",
    "loadingRecords": "A carregar...",
    "processing": "<i class=\"fa fa-spinner fa-spin\" style=\"font-size:24px;color:rgb(75, 183, 245);\"></i>",
    "lengthMenu": "_MENU_",
    "zeroRecords": "Não foram encontrados resultados",
    "search": "Procurar:",
    "paginate": {
        "first": '<i class="first"></i>',
        "last": '<i class="last"></i>',
        "next": '<i class="next"></i>',
        "previous": '<i class="previous"></i>'
    },
    "info": "Mostrando os registos _START_ a _END_ num total de _TOTAL_",
    "infoEmpty": "Sem resultados",
}
