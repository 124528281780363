if($('#productStockDatatable').length > 0) {
    url = $('#productStockDatatable').attr('data-url');
    dt = $("#productStockDatatable").DataTable({
        searchDelay: 500,
        order: [[0, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data : 'user'},
            {data : 'stock'},
            {data : 'actions'},
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: false
            },
        ]
    });

    dt.on('draw', function() {
        refreshFsLightbox();
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-productStockDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}
