if($('#userSessionsDatatable').length > 0) {
    url = $('#userSessionsDatatable').attr('data-url');
    dt = $("#userSessionsDatatable").DataTable({
        searchDelay: 500,
        order: [[0, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            { data : 'partnerRel.label' },
            { data : 'number' },
            { data : 'shoots' },
            { data : 'finished' },
            { data : 'confirmed' },
            { data : 'confirmed_date' },
            { data : 'session_date' },
            { data : 'total_no_products' },
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: true
            },
            {
                targets: 4,
                orderable: true
            },
            {
                targets: 5,
                orderable: true
            },
            {
                targets: 6,
                orderable: true
            },
            {
                targets: 7,
                orderable: true
            },
        ]
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-userSessionsDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}
