$('body').on('change', '#whatsapp-mass-message-form input[name="type"]', function () {
    const status = $('.whats-app-mass-send-component-partner-status:checked').val();
    $('#whatsapp-mass-message-form #whats-app-mass-send-component-partners-wrapper').removeClass('d-none');
    $('#whatsapp-mass-message-form #whats-app-mass-send-component-customers-wrapper').addClass('d-none');
    $('#whatsapp-mass-message-form #whats-app-mass-send-component-message-wrapper').removeClass('d-none');

    $('#whatsapp-mass-message-form select').val('').trigger('change');

    showMassWhatsAppWarning($(this).val(), 0, 0, status);
});

$('body').on('change', '#whatsapp-mass-message-form #partner_id', function () {
    const status = $('.whats-app-mass-send-component-partner-status:checked').val();
    const customersWrapper = $('#whatsapp-mass-message-form #whats-app-mass-send-component-customers-wrapper');
    const customersSelectWrapper = $('#whatsapp-mass-message-form #whats-app-mass-send-component-customers-select-container');
    const messageWrapper = $('#whatsapp-mass-message-form #whats-app-mass-send-component-message-wrapper');

    const type = $('#whatsapp-mass-message-form input[name="type"]:checked').val();
    const partner_id = $(this).val();
    const url = customersWrapper.attr('data-url');

    if ($(this).val().length === 0) {
        $('#whatsapp-mass-message-form #customer_id').val('').trigger('change');
        showMassWhatsAppWarning(type, 0, 0, status);
        return;
    }

    if (type === 'partners') {
        showMassWhatsAppWarning(type, 1, 0, status);
        return;
    }

    $.ajax({
        url: url,
        type: 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {partner_id: partner_id},
        success: function (data) {
            customersSelectWrapper.html(data.html);
            customersSelectWrapper.find('select').each(function () {
                $(this).select2({
                    dropdownParent: '#whats-app-mass-send-component-customers-wrapper',
                    placeholder: 'Escolha um cliente',
                    results: function (data) {
                        return {results: data};
                    }
                });
            });
            customersWrapper.removeClass('d-none');
            showMassWhatsAppWarning(type, 1, 0, status);
        }
    });
})

$('body').on('change', '.whats-app-mass-send-component-partner-status', function () {
    const type = $('#whatsapp-mass-message-form input[name="type"]:checked').val();
    console.log(type);
    const partnersSelectWrapper = $('#whats-app-mass-send-component-partners-select-container');
    const value = $('.whats-app-mass-send-component-partner-status:checked').val();
    const url = $(this).attr('data-url');
    console.log(url);

    $.ajax({
        url: url,
        type: 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {status: value},
        success: function (data) {
            partnersSelectWrapper.html(data.html);
            partnersSelectWrapper.find('select').each(function () {
                $(this).select2({
                    dropdownParent: '#whats-app-mass-send-component-partners-wrapper',
                    placeholder: 'Escolha um parceiro',
                    results: function (data) {
                        return {results: data};
                    }
                });
            });
            partnersSelectWrapper.removeClass('d-none');
            showMassWhatsAppWarning(type, 0, 0, value);
        }
    });
});

$('body').on('change', '#whatsapp-mass-message-form #customer_id', function () {
    const status = $('.whats-app-mass-send-component-partner-status:checked').val();

    if ($(this).val().length === 0) {
        showMassWhatsAppWarning('customers', 1, 0, status);
        return;
    }

    showMassWhatsAppWarning('customers', 1, 1, status);
});


function showMassWhatsAppWarning(type, hasPartner, hasCustomer, status) {
    console.log(
        type, hasPartner, hasCustomer, status
    );
    const selector = '.whatsapp-mass-warning[data-type="' + type + '"][data-has-partner="' + hasPartner + '"][data-has-customer="' + hasCustomer + '"]';

    $('.whatsapp-mass-warning').addClass('d-none');
    $('.whatsapp-mass-warning-status').addClass('d-none');

    $(selector + ' .whatsapp-mass-warning-status[data-status="' + status + '"').removeClass('d-none');
    $(selector).removeClass('d-none');
}
