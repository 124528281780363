body = $('body');

body.on('change', '.quality_satisfaction', function () {
    var val = parseInt($(this).val());
    if (val === 1) {
        $('#satisfaction_container').addClass('d-none');
    } else {
        $('#satisfaction_container').removeClass('d-none');
    }
});

body.on('change', '.quality_suggestions_question', function () {
    var val = parseInt($(this).val());
    if (val === 1) {
        $('#suggestions_container').removeClass('d-none');
    } else {
        $('#suggestions_container').addClass('d-none');
    }
});

body.on('change', '.quality_customer_insatisfaction', function () {
    var val = parseInt($(this).val());
    if (val === 1) {
        $('#insatisfaction_container').removeClass('d-none');
    } else {
        $('#insatisfaction_container').addClass('d-none');
    }
});

body.on('change', '.feedback-toggle-hidden', function () {
    if (!$(this).hasClass('feedback-toggle-hidden')) {
        return;
    }

    let hiddenId = '#' + $(this).attr('data-feedback-toggle-hidden-id');
    let hiddenValue = parseInt($(this).attr('data-feedback-toggle-hidden-value'));
    let name = $(this).attr('name');

    let currentValue = parseInt($('input[name="' + name + '"]:checked').val());

    let hiddenEl = $(hiddenId);

    hiddenEl.addClass('d-none');
    hiddenEl.find('input').prop('required', false);
    if (currentValue === hiddenValue) {
        hiddenEl.removeClass('d-none');
        hiddenEl.find('input').prop('required', true);
    }
})

window.agreementAnotherTypeChange = function () {
    const anotherPartnerCheckedEl = $('input[name="another_partner"]:checked');
    let anotherPartnerIsChecked = parseInt(anotherPartnerCheckedEl.val()) === 1;

    let sessionsInput = $('input[name="sessions"]');
    let whenInput = $('input[name="when"]');

    sessionsInput.prop('required', anotherPartnerIsChecked);
    whenInput.prop('required', anotherPartnerIsChecked);
}
