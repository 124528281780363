if ($('#userShootsDatatable').length > 0) {
    url = $('#userShootsDatatable').attr('data-url');
    dt = $("#userShootsDatatable").DataTable({
        searchDelay: 500,
        order: [[4, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data: 'machine'},
            {data: 'type'},
            {data: 'label'},
            {data: 'total'},
            {data: 'date'},
            {data: 'actions'},
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                orderable: true
            },
            {
                targets: 3,
                orderable: true
            },
            {
                targets: 4,
                orderable: true
            },
            {
                targets: 5,
                orderable: false
            },
        ]
    });

    dt.on('draw', function () {
        refreshFsLightbox();
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-userShootsDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
}
