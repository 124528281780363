if($('#globalCustomersDatatable').length > 0) {
    url = $('#globalCustomersDatatable').attr('data-url');
    dt = $("#globalCustomersDatatable").DataTable({
        order: [[7, 'desc']],

        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: url,
        pageLength: 25,
        searchDelay: 2000,
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data: 'first_name'},
            {data: 'gender'},
            {data: 'email'},
            {data: 'phone'},
            {data: 'partners'},
            {data: 'city'},
            {data: 'dob'},
            {data: 'actions'},
            {data: 'created_at'},
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true,
            },
            {
                targets: 2,
                orderable: true,
            },
            {
                targets: 3,
                orderable: true,
            },
            {
                targets: 4,
                orderable: true,
                searchable: false,
            },
            {
                targets: 5,
                orderable: true,
            },
            {
                targets: 6,
                orderable: true,
            },
            {
                targets: 7,
                orderable: false,
                searchable: false
            },
            {
                targets: 8,
                orderable: false,
                searchable: false,
                visible: false
            }
        ]
    });

    const filterSearch = document.querySelector('[data-kt-docs-table-filter="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });

    $('body').on('click', '.modal_customer_add_partner_btn', function() {
        let id = $(this).attr('data-id');
        let url = $(this).attr('data-url');
        let method = $(this).attr('data-method');
        $.ajax({
            url: url,
            type: method,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            data: {
                id: id
            },
            success: function (data) {
                $('#modal_customer_add_partner .modal-body').html(data.html);
                $('#modal_customer_add_partner_name').html(data.name);
                KTImageInput.createInstances();
                $('#modal_customer_add_partner').modal('show');
            }
        });
    });
}
