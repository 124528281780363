body = $('body');


body.on('change', '#ai_zone_1', function () {
    $('#ai_zone_2').find('option').prop('disabled', false);
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    let zone = $(this).val();
    let zoneGroup = $('#ai_zone_option_' + $(this).val()).attr('data-zone-group')
    let session = $('#session').val();
    let selectedZone = 'zone_1';
    if ($(this).val()) {
        $('#ai_zone_2').find('option[value=' + $(this).val() + ']').prop('disabled', true);
        getAIZone1(url, method, zone, zoneGroup, session, selectedZone);
    } else {
        $('#AI_zone_info_wrapper_1').html('')
    }
});

function getAIZone1(url, method, zone, zoneGroup, session, selectedZone) {
    let zone_1 = $('#ai_zone_1').val();
    let zone_2 = $('#ai_zone_2').val();
    let unique = $('#unique').val();
    var agreement = $('#agreement').val();
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            selectedZone: selectedZone,
            session: session,
            zone: zone,
            zoneGroup: zoneGroup,
            zone_1: zone_1,
            zone_2: zone_2,
            agreement: agreement,
            unique: unique
        },
        success: function (data) {
            $('#AI_zone_info_wrapper_1').html(data.html);
            KTImageInput.createInstances();
            if ($('#unique_zone_alert').length > 0 && $('#unique_zone_alert').attr('data-zone') === 'zone_2') {
                $('#ai_zone_2').trigger('change');
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

body.on('change', '#ai_zone_2', function () {
    $('#ai_zone_1').find('option').prop('disabled', false);
    let url = $(this).attr('data-url');
    let method = $(this).attr('data-method');
    let zone = $(this).val();
    let zoneGroup = $('#ai_zone_option_' + $(this).val()).attr('data-zone-group')
    let session = $('#session').val();
    let selectedZone = 'zone_2';
    if ($(this).val()) {
        $('#ai_zone_1').find('option[value=' + $(this).val() + ']').prop('disabled', true);
        getAIZone2(url, method, zone, zoneGroup, session, selectedZone);
    } else {
        $('#AI_zone_info_wrapper_2').html('')
    }
});

function getAIZone2(url, method, zone, zoneGroup, session, selectedZone) {
    let zone_1 = $('#ai_zone_1').val();
    let zone_2 = $('#ai_zone_2').val();
    let unique = $('#unique').val();
    var agreement = $('#agreement').val();
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            selectedZone: selectedZone,
            session: session,
            zone: zone,
            zoneGroup: zoneGroup,
            zone_1: zone_1,
            zone_2: zone_2,
            agreement: agreement,
            unique: unique
        },
        success: function (data) {
            $('#AI_zone_info_wrapper_2').html(data.html);
            KTImageInput.createInstances();
            if ($('#unique_zone_alert').length > 0 && $('#unique_zone_alert').attr('data-zone') === 'zone_1') {
                $('#ai_zone_1').trigger('change');
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

function getMinMaxShoots(url, price_id) {
    let method = 'POST';
    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            price_id: price_id,
        },
        success: function (data) {
            let label = 'Disparos Recomendado : ' + data.min + '-' + data.max;
            let placeholder = 'Recomendado : (' + data.min + '-' + data.max + ')';

            $('#shoots_label').html(label);
            $('#shoots').attr('placeholder', placeholder)
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status == 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

body.on('change', '.AI_price_radio', function () {
    let checkedBtn = $('.AI_price_radio:checked');
    let zones = parseInt($(checkedBtn).attr('data-zones'));

    let zoneWrapper2 = $('#AI_zone_2_wrapper');
    if (zones === 1) {
        zoneWrapper2.addClass('d-none');
        $('#AI_zone_info_wrapper_2').html('');
    } else {
        zoneWrapper2.removeClass('d-none');
    }

    getMinMaxShoots($(checkedBtn).attr('data-url'), $(checkedBtn).val())
});
