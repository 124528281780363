window.currencySymbol = '€';
window.activateMaskers = function maskers() {
    $("body").find("input.currency-mask").maskMoney({
        prefix: '',
        suffix: currencySymbol,
        decimal: '.',
        thousands: ' '
    });
    $('body input.percentage-mask').mask('##0.00%', {reverse: true});

    $('body select.select2').select2();
}

window.getFloatFromMoney = function getFloatFromMoney(money, currency) {
    money = money.replace(currency, '');
    money = money.replace(' ', '');
    return parseFloat(money);
}

window.getIntFromPercentage = function getIntFromPercentage(tax) {
    tax = tax.replace('%', '');
    tax = tax.replace(' ', '');
    return parseInt(tax);
}

window.number_format = function number_format(number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

window.copyToClipboard = function copyToClipboard(text) {
    const elem = document.createElement('textarea');
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
}

activateMaskers();
