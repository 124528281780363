body = $('body');


body.on('click', '.DL2025_zoneGroup_radio', function () {
    const zoneGroupDL2025Id = $(this).val();
    const customerId = $('#customer').val();
    const zoneGroupDL2025getZonesUrl = $(this).data('url');
    let DL2025PackContainer = $('#DL2025_pack_container');

    DL2025PackContainer.find('input[type="radio"][value="1"]').prop('checked', true);
    let zoneGroupDL2025IdInt = parseInt(zoneGroupDL2025Id);
    if (zoneGroupDL2025IdInt !== 3) {
        DL2025PackContainer.removeClass('d-none');
    } else {
        DL2025PackContainer.addClass('d-none');
    }

    $.ajax({
        url: zoneGroupDL2025getZonesUrl,
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            customer_id: customerId,
            zone_group_dl_2025_id: zoneGroupDL2025Id
        },
        success: function (response) {
            let options = '<option value="" disabled selected>Selecione uma zona</option>';

            let shouldSelect = '';
            if (response.zones.length === 1) {
                shouldSelect = 'selected';
            }

            $.each(response.zones, function (index, zone) {
                options += '<option value="' + zone.id + '" data-shoots-min="' + zone.shoots_min + '" data-shoots-max="' + zone.shoots_max + '" ' + shouldSelect + '>' + zone.label + '</option>';
            });

            const zoneDL2025IdEl = $('#zone_dl_2025_id')

            zoneDL2025IdEl.html(options);

            setTimeout(function () {
                if (response.zones.length !== 1) {
                    $('.dl2025_shoots_wrapper').addClass('d-none');
                } else {
                    zoneDL2025IdEl.trigger('change');
                }
            }, 100);
        }
    });
});

body.on('change', '#zone_dl_2025_id', function () {
    const DL2025ShootsWrapper = $('.dl2025_shoots_wrapper');
    const priceEl = $('#price');
    if ($(this).val() === '') {
        priceEl.val('');
        DL2025ShootsWrapper.addClass('d-none');
        return;
    }

    getDL2025Price();

    DL2025ShootsWrapper.removeClass('d-none');

    const shootsMin = $(this).find('option:selected').data('shoots-min');
    const shootsMax = $(this).find('option:selected').data('shoots-max');

    const label = 'Disparos Recomendado : ' + shootsMin + ' - ' + shootsMax;

    $('.shoots_label').html(label);

    $('#shoots').attr('placeholder', label);
});

body.on('change', '.DL2025_price', function () {
    getDL2025Price();
});

function getDL2025Price() {
    const priceInput = $('#price_id');
    const url = priceInput.data('url');
    const method = priceInput.data('method');
    const paidSessions = $('.DL2025_price:checked').val();
    const zoneDL2025Id = $('#zone_dl_2025_id').val();
    const partnerId = $('#partner').val();

    $.ajax({
        url: url,
        type: method,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: {
            paid_sessions: paidSessions,
            zone_dl_2025_id: zoneDL2025Id,
            partner_id: partnerId
        },
        success: function (data) {
            if (data.status === true || data.status === 'true') {
                priceInput.val(data.price_table_dl_2025_id);
            }
        },
        error: function (err) {
            $('.ajaxFormError').remove();
            if (err.status === 422) { // when status code is 422, it's a validation issue

                $('#success_message').fadeIn().html(err.responseJSON.message);
                // display errors on each form field
                $.each(err.responseJSON.errors, function (i, error) {
                    var el = $(document).find('[name="' + i + '"]');
                    //el.after($('<div class="ajaxFormError mt-1 w-100 toast mb-2 d-flex align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><div class="d-flex align-items-center"><i class="fas fa-danger mr-1"></i>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</div></div></div>'));
                    el.after($('<div class="ajaxFormError alert alert-danger mt-2"><span class="svg-icon svg-icon-danger svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black"/><rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black"/><rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black"/></svg></span><div class="d-flex flex-column"><h4 class="mb-1 text-dark">Alerta</h4><span>' + error[0].replace(i.replace('_', ' '), el.attr('data-title')) + '</span></div></div>'));
                    //el.after($('<span class="alert-error">'+error[0].replace(i.replace('_', ' '), el.attr('data-title'))+'</span>'));
                });
            }
        },
        complete: function () {
            $('button[data-kt-indicator="on"]').removeAttr('data-kt-indicator');
        }
    });
}

body.on('change', '.phototype_radio_DL2025', function () {
    $.ajax({
        url: $(this).data('url'),
        type: 'GET',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        success: function (response) {
            $('.phototype_details_table_wrapper').html(response.data.html);
            $('.phototype_details_wrapper').removeClass('d-none');
        }
    });
});
