import printJS from "print-js";

if ($('#userTimetracksDatatable').length > 0) {
    let url = $('#userTimetracksDatatable').attr('data-url');
    let dt = $("#userTimetracksDatatable").DataTable({
        searchDelay: 500,
        order: [[0, 'desc']],
        language: window.datatableLanguage,
        searching: true,
        serverSide: true,
        processing: true,
        responsive: true,
        ajax: {
            "url": url,
            "type": "GET",
            "data": function (d) {
                d['date_range_filter'] = $('#userTimetracksDatatable_date_range_filter').val();
                d['date_range_filter_status'] = $('#userTimetracksDatatable_date_range_filter_status').prop('checked');
            }
        },
        select: {
            style: 'os',
            selector: 'td:first-child',
            className: 'row-selected'
        },
        columns: [
            {data: 'user', name: 'user.first_name'},
            {data: 'created_at', name: 'created_at'},
            {data: 'duration', name: 'duration'},
            {data: 'started_at', name: 'started_at'},
            {data: 'ended_at', name: 'ended_at'},
            {data: 'actions', name: 'actions'},
            {data: 'user_last_name', name: 'user.last_name'},
            {data: 'user_email', name: 'user.email'},
        ],
        columnDefs: [
            {
                targets: 0,
                orderable: true
            },
            {
                targets: 1,
                orderable: true
            },
            {
                targets: 2,
                searchable: false,
                orderable: false
            },
            {
                targets: 3,
                orderable: true
            },
            {
                targets: 4,
                orderable: true
            },
            {
                targets: 5,
                orderable: false,
                searchable: false
            },
            {
                targets: 6,
                orderable: false,
                visible: false
            },
            {
                targets: 7,
                orderable: false,
                visible: false
            },
        ]
    });

    const filterSearch = document.querySelector('[data-kt-table-filter-userTimetracksDatatable="search"]');
    filterSearch.addEventListener('keyup', function (e) {
        dt.search(e.target.value).draw();
    });
    $('body').on('change', '#userTimetracksDatatable_date_range_filter, #userTimetracksDatatable_date_range_filter_status', function () {
        dt.draw();
    })
}

$('#print-current-user-timetrack').on('click', function () {
    let cssArr = [];
    $('link[rel="stylesheet"]').each(function () {
        cssArr.push($(this).attr('href'));
    });
    console.log(cssArr);
    printJS({
        printable: 'user-timetrack-wrapper',
        type: 'html',
        css: cssArr
    })
})
